/*######################################################
	setup complete page
#######################################################*/
.setup-complete {
    padding-top: 80px;
    padding-bottom: 100px;

    @include break-xs {
	    padding-top: 20px;
        padding-bottom: 20px;
	}

    h2 {
        margin-bottom: 1em;
    }

    p {
        margin-bottom: 2em;
    }
}
