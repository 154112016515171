﻿/*######################################################
	tab dropdown
#######################################################*/
.tabdropdown{

}
.tabdrpd-heading{
	@include clearfix;


}
.tabdrpd-btnlink{
	font-size: 2.2em;
	border: none;
	padding: 0 30px;
	width: 100%;
	position: relative;
    display: none;

	&:hover, &:focus, &:active{
		text-decoration: none;
	}

	@include break-sm{
		display: inline-block;
		padding: 10px 30px 10px 0;
	}
   
}
.tabdrpd-activelabel{
	width: 100%;
	display: inline-block;
	padding-right: 30px;
}
.tabdrpd-icon{
	font-size: 8px;
	position: absolute;
	right: 8px;
	top: 50%;
    margin-top:-4px;

}
.tabdrpd-list {
	@include transition(max-height linear .3s);
    position: relative;
    left: auto;
    right: auto;
    width: 100%;
    border: none;
    box-shadow: none;
	border-bottom: 1px solid;
	padding: 0;
	border-radius: 0;
	transform: scale(1, 1);
	opacity: 1;
	counter-reset: section; 

	.tabdrpd-heading.open &{
		max-height: 500px;
	}

	@include break-sm (true) {
		border-collapse: collapse;
		display: table;
		table-layout: inherit;
	}

	@include break-sm {
		max-height: 0;
		overflow: hidden;
		margin-top: 5px;
	}
}
.tabdrpd-item {
	position: relative;
	text-align: center;

	&:after {
		@include break-sm (true) {
			.is-wzrdwelmode & {
			    counter-increment: section;
			    content: "Step " counter(section);
			    font-size: 1.5rem;	
			    position: absolute;
			    top: 0;
			    left: 50%;
			    margin-left: -23px;	
			    z-index: 0;
			}
		}
	}

	&:before {
		@include break-sm {
			display: none;
		}

		.is-wzrdwelmode & {
				@include extend-icomoon;
				@extend .icon-pagination-right;	
				font-weight: bold;
				font-size: 1.4rem;
				position: absolute;
				top: 20px;
				right: 0;
		}
	}

	&:first-child{
		@include break-sm {
			border-top: 1px solid;
			padding-top: 10px;
		}
	}
	
	&:last-child{
		@include break-sm {
			padding-bottom: 15px;
		}
	}

	@include break-sm (true) {
		display: table-cell;
	}

	@include break-sm {
		float: none;
	}
}
.tabdrpd-link {
	.tabdrpd-list li & {
		@include border-radius(0);
		display: block;
		font-size: 2em;
		border: none;
		margin: 0;
		padding: 20px 15px 30px 5px;
		position: relative;
		z-index: 5;

		&.active {
			border-bottom: 5px solid !important;
		}

		@include break-sm {
			padding: 10px;
			font-size: 1.5em;
		}
	}
}
.tabdrpd-tabcontent {
    > .nav-tabs {
        display:none;
    }
}


.is-wzrdwelmode .last-tab.tabdrpd-item:before {
		display:none;
}