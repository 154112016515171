/*######################################################
	navigation
#######################################################*/
#mainNavigation {
    @include transition(right .3s ease-in);
    padding: 30px 20px;
    position: fixed;
    overflow-y: auto;
    right: -250px;
    top: 0;
    height: 100%;
    width: 250px;
    z-index: 1;

    .is-sidebaropen & {
        right: 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
    }

    a {
        @include transition(color linear .2s);
        color: #fff;
        display: block;
        font-size: 2em;
        font-weight: normal;
        padding: 6px 0;

        &:hover,
        &:focus,
        &:active {
            color: darken(#ffffff, 40%);
            text-decoration: none;
        }
    }
}

.top-menu-nav {
    float: left;
    margin: 14px 0;    

    > ul {
        margin: 0;
        padding: 0;

        > li {
            display: inline-block;
            margin-left: 20px;

            &.is-selected {
                > a {
                    color: #000;
                }
            }

            &:first-child {
                margin-left: 0;
            }


            a {
                @include transition(color linear .2s);
                font-size: 14px;
                display: block;
                font-weight: 500;

                &:hover,
                &:focus,
                &:active {
                    color: #000;
                    text-decoration: none;
                }
            }

            .dropdown-toggle {
                &:hover {
                    text-decoration: none;
                }

                @include break-sm{
                    display: none;
                }
            }
            .dropdown-menu{
                @include break-sm{
                    position: relative;
                    top: auto;
                    left: -5px;
                    transform: scale(1, 1);
                    opacity: 1;
                    box-shadow: none;
                    padding: 0;
                    border: none;
                    z-index: 11;
                }

                > li > a{
                    &:hover, &:focus, &:active{
                        @include break-sm{
                            background-color: transparent;
                        }
                    }
                }
            }
            @include break-sm{
                display: block;
                margin-left: 0;
                padding: 5px 0;
            }
        }

        ul.dropdown-menu li {
            display: block;
            margin-left: 0;
        }
    }
    @include break-sm{
        display: inline-block;
        float: none;

    }
}

.top-menu {
    @include clearfix;
    float: right;

    .navbar-nav {
        font-size: 1.6em;
        font-weight: 500;
        float: left;

        > li {
            display: inline-block;

            > a {
                @include transition (color ease-in .2s);
                background-color: transparent;

                &:hover,
                &:focus,
                &:active {
                    background-color: transparent;
                }
            }

            &.open > a {
                background-color: transparent;

                &:hover,
                &:focus {
                    background-color: transparent;
                }
            }
        }

        .dropdown-menu {
            font-size: .8em;
            left: inherit;
            right: 0;
        }
    }

    .icon-chevron-dropdown {
        font-size: .5em;
        font-weight: normal;
    }

    .nav > li > a:hover,
    .nav > li > a:focus {
    }

    @include break-sm{
        float: none;
        text-align: left;
    }
}
.top-menu-main{
    .icon-close{
        font-size: 20px;
        position: absolute;
        top: 24px;
        right: 24px;
        display: none;

        &:hover{
            cursor: pointer;
        }

        @include break-sm{
            display: block;
        }
    }
}
.menu-toggle {
    background-color: transparent;
    border: none;
    font-size: 16px;
    float: right;
    margin-top: 14px;
    outline: none;
    display: none;

    span {
        position: relative;
        font-weight: 500;
        top: -3px;
        right: 0;

        @include break-md {
            display: none;
        }
    }

    .icon-mainmenus {
        font-size: 20px;
    }

    @include break-sm{
        display: inline-block;
    }
}

.top-menu-links {
    float: left;
    padding-right: 20px;

    @include break-sm {
        display: none;
    }
}

.customer-topbar {
    .single-account {
        font-size: 1.8em;
        margin-top: 14px;
        margin-left: 20px;

        @include break-sm{
            float: none !important;
            width: 100%;
            display: block;
        }
    }
}
