/*######################################################
	services page
#######################################################*/
.services {	
	padding: 20px 0 10px 0;
}

.svcs-group {
	border-collapse: collapse;
	border-top: 1px solid;
	display: table;
	table-layout: fixed;
	width: 100%;

	@include break-xs {
		display: block;
	}

}

.svcs-num,
.svcs-form {
	display: table-cell;
	vertical-align: top;

	@include break-xs {
		display: block;
		width: 100% !important;
	}
}

.svcs-num {
	font-size: 1.5em;
	font-weight: 500;
	line-height: normal;
	padding: 40px 10px 10px 10px;
	width: 48px;

	@include break-xs {
		padding: 20px 10px 5px 10px;
	}
}

.svcs-form {
	@include clearfix;
	padding: 36px 0 10px 0;
	width: 100%;

	@include break-xs {
		padding: 5px 10px 20px 10px;
	}
}
.svcs-colwrap {
	@include make-row;
}
.svcs-column {
	float: left;
	
	@include break-md(true) {
		width: 50%;

		~ .svcs-column {
			padding-left: 20px;
		}
	}

	@include break-md {
		width: 100%;
	}
}

.svcs-itemwrap {
	padding: 0 10px;	
	float: left;
	@include break-xs(true) {
		width: 50%;
	}

	@include break-xs {
		width: 100%;
	}
}

.svcs-btnwrap {
	text-align: right;
	position: relative;

	@include break-sm {
		text-align: center;
		padding-top: 50px;
	}
}

.svcs-addnew {
	position: absolute;
	left: 50%;
	margin-left: -30px !important;
	top: -30px;
}

.servicestabwrap-navtabs{
    list-style: none;
    margin: 20px 0 0;
    padding: 0;
    display: none;

    @include break-sm{
        display: block;
        @include transition(max-height linear .3s);
        max-height: 0;
        overflow: hidden;
    }

    .servicestabwrap-tabsmobile.open &{
        @include break-sm{
            max-height: 300px;
        }
    }
}

.servicestabwrap-navitemtab{
    display: block;
}

.servicestabwrap-navlinktab{
    font-size: 2em;
    padding: 0 30px 20px 10px;
    display: block;

    &:hover, &:focus, &:active{
        text-decoration: none;
    }
}

