/*######################################################
	reminder strategy template page
#######################################################*/

.remstratem-template-panel {
    padding-top: 30px;
    background-color: #fff;
}

.remstratem-col-wrap {
    border-collapse: collapse;
    display: table;
    table-layout: fixed;
    width: 100%;
    @include break-md {
        display: block;
    }
}

.remstratem-col {
    display: table-cell;
    vertical-align: top;
    @include break-md {
        display: block;
    }
}

.remstratem-label {
    font-size: 1.4em;
    font-weight: 500;
}

.remstratem-btnwrap {
    margin-right: 20px;
    padding-top: 20px;
    text-align: center !important;
    &:before {
        border-top: 1px solid;
        content: "";
        display: block;
        position: absolute;
        left: 0;
        margin-top: 9px;
        top: 50%;
        width: 100%;
        z-index: 0;
    }
}

.remstratem-title {
    font-size: 2em;
    padding: 20px 26px;
}

.remstratem-message {
    padding-top: 22px;
}

.remstratem-mergedfields {
    //@include clearfix;
    span {
        display: inline-block; //float: left;
        font-size: 1.3em;
        min-width: 158px;
        padding: 6px 5px;
    }
    li {
        list-style: none;
    }
    ul {
        padding-left: 10px;
    }
}

.form-template {
    textarea {
        height: 350px;
        line-height: 26px;
    }
    .radio label {
        font-weight: normal;
    }
}

/* col left */

.remstratem-col-left {
    border-bottom: 2px solid;
    border-right: 2px solid;
    padding: 0 0 40px 40px;
    width: 450px;
    @include break-md {
        width: 100%;
    }
}

.remstratem-templateitem {
    cursor: pointer;
    padding-right: 20px;
    &.is-template-active {
        border-right: 6px solid;
        padding-right: 0;
    }
    ~.remstratem-templateitem {
        margin-top: 10px;
    }
}

.remstratem-templateinner {
    @include border-radius(5px);
    border: 2px solid;
    .is-template-active & {
        @include border-radius(5px 0 0 5px);
        border-right: none;
    }
}

.remstratem-tmpinner-colwrap {
    border-collapse: collapse;
    display: table;
    table-layout: fixed;
    width: 100%;
}

.remstratem-tmpinner-col {
    display: table-cell;
    padding: 5px;
    vertical-align: top;
}

.remstratem-tmpinner-icon {
    text-align: center;
    width: 60px;
    p,
    span {
        font-size: 1.5em;
    }
    [class^="icon-"],
    [class*="icon-"] {
        display: block;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .icon-envelope {
        font-size: 1.3em;
    }
    .icon-letter {
        font-size: 1.6em;
        margin-left: 7px;
    }
    .icon-comment {
        font-size: 1.6em;
    }
}

.remstratem-tmpinner-content {
    h4 {
        @include ellipsis;
        font-size: 1.5em;
        line-height: normal;
    }
    span,
    p {
        @include ellipsis;
        font-size: 1.5em;
    }
}

.remstratem-tmpinner-action {
    padding-left: 10px;
    text-align: left;
    vertical-align: middle;
    width: 40px;
    .icon-delete {
        color: #ededed;
        font-size: 1.8em;
    }
    .is-template-active & {
        width: 58px;
    }
}

/* col right */

.remstratem-col-right {
    border-bottom: 2px solid;
    padding: 20px 0 40px 40px;
    @include break-md {
        width: 100%;
    }
}

.remstratem-masthead {
    padding-right: 40px;
}

.remstratem-templatenamewrap {
    padding-bottom: 5px;
    padding-top: 5px;
    .control-label {
        line-height: 45px;
    }
}

.remstratem-template {
    @include clearfix;
    padding-right: 40px;
}