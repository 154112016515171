/*######################################################
	operator dashboard page
#######################################################*/
.dashboard-side {
	margin-top: 30px;
}

.content-wrapper {

	&-header {
		border-bottom: 2px solid;
	}
	&-note {
		font-size: 1.3em;
		text-transform: uppercase;
		margin: 6px 0;
	}
		&-footnote {
		font-size: 1.5em;
	}
	&-footer {

	}
}

.table-dashboard {
	td {
		padding: 16px 10px;

		@include break-sm{
			display: table-cell;
		}
	}
	.td-tick {
		width:10%;

		@include break-sm{
			display: table-cell;
		}
	}
	.td-title {
		width: 50%;
	}
	.td-action {
		width: 15%;

		@include break-sm{
			display: table-cell;
		}
	}
	.td-pricing {
		width: 20%;
	}
	&-subnote {
		font-size: 0.9em;
		margin-top: 5px;
	}

}