﻿/*######################################################
  file-upload
#######################################################*/
.custom-file-upload {
    @include transition(all ease-in .2s);

    border: 1px solid;
    border-radius: 4px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: 500;

    input[type="file"] {
        display: none;
    }
}