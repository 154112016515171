﻿/*######################################################
    contact page
#######################################################*/
#contact{
}
.contact-list{
    position:relative;

    .table-grid .nav-tabs > li > a{
        padding-left:0
    }

    .table-contact td.td-email > a > span{
        @include ellipsis;
        max-width: 150px;
    }
    .table-contact th.th-action{
        padding-right:20px;
    }    
    .table-contact td.td-action{
        width:200px;

    }
    .table-contact  td .btn-detail{
        right:10px;
    }
    .table-contact td .btn-call{
        right: 65px;
        margin-right: 15px;
    }

}
.contact-button{
    position:absolute;
    right: 0;
    top:-5px;

    .btn-add-contact {
            padding: 12px 15px;
    }
}
.contact-checkrole{
    margin-top:13px;
}