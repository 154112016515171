/*######################################################
  tab
#######################################################*/
.nav-tabs { 
	> li {
		> a {
			// color: $gray-lighter; color is belong to skin's property

			.is-tabvar-2 & {
				height: 80px;
				padding-bottom: 0;
				line-height: 1;
			}
		}
	}
}

li.uib-tab {
	span {
		display: block;
		text-align: center;
	}

	em {
		font-size: 1.6rem;
		font-style: normal;
	}

	&.is-uibtab-warning,
	&.is-uibtab-noticed {
		em {
			color: #fff;
			display: inline-block;
			padding: 1px 3px;
		}
	} 

	&.is-uibtab-warning {
		em {
			border-bottom-color: $brand-danger;
		}
	} 

	&.is-uibtab-noticed {
		em {
			border-bottom-color: $brand-warning;
		}
	} 
}


.tab-content{
    position:relative;
}
.tabdrop-btn {
	@include border-radius(0);
	background-color: transparent;
	border: none;
	border-bottom: 1px solid;
	display: none;
	font-size: 2.2em;
	font-weight: 500;
	padding: 20px 40px 20px 10px;
	position: relative;
	line-height: normal;
	text-align: left;
	width: 100%;

	&:hover,
    &:active,
    &.focus,
	&:focus,
	&.active {
		background-color: transparent !important;
	}

	.icon-chevron-dropdown {
		@include transition(transform linear .2s);
		display: block;
		font-size: 8px;
		height: 20px;
		line-height: 20px;
		position: absolute;
		right: 6px;
		top: 50%;
		margin-top: -10px;
		text-align: center;
		width: 20px;

		.is-tabopen & {
			@include transform(rotate(-180deg));
		}
	}

	.tab-dropdown-sm & {
		@include break-sm {
			display: block;
			border-bottom-color: transparent;
		}
	}
}

.tabdrop-nav {
	@include transition(max-height linear .2s);
	@include break-sm {
		.tab-dropdown-sm & {		
				margin: 0;
				max-height: 0;
				overflow: hidden;
				padding: 0;
				> li {
					display: block;

					> a {
						border: none;
						display: block;
						padding: 10px;
						text-align: left;

						&:hover,
						&:active,
						&:focus {
							// color: $brand-primary; color is belong to skin's property
						}
					}

					&.active > a {
						//color: $gray-lighter; color is belong to skin's property

						&:hover,
						&:active,
						&:focus {
							// color: $gray-lighter; color is belong to skin's property
						}
					}

					&:first-child {
						padding-top: 20px;
					}
					&:last-child {
						padding-bottom: 20px;
					}
				}


			}

		.is-tabopen & {
			max-height: 300px;
		}

	}
}