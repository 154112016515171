/*######################################################
	payment history page
#######################################################*/
.payment-history{
    .td-action{

        @include break-sm{
            display: none;
        }
    }
    table > tbody > tr{
        &.show-detail{
            > td{
                > span{
                    @include break-sm{
                        display: inline-block;
                    }
                }

                &.td-tick{
                    @include break-sm{
                        float: left;
                    }
                    > span{
                        @include break-sm{
                            padding-top: 0px;
                            padding-left: 4px;
                        }
                    }
                }

                &.td-action{
                    > button{
                        @include break-sm{
                            display: inline-block;
                            position: relative;
                            top: auto;
                            right: auto;
                            margin: 0px 0 15px;
                        }
                    }
                }

                &.td-receipt{
                    @include break-sm{
                        padding-top: 15px;
                    }
                }

                @include break-sm{
                    display: inline-block;
                    width: 85%;
                    text-align: left;
                    border-color: transparent;
                    height: auto;
                    padding-top: 10px;
                    padding-bottom: 5px;
                    float: right;
                }
            }

            .td-label{
                @include break-sm{
                    display: inline-block;
                }
            }

            &.is-selected{
                td{
                    @include break-sm{
                        border-color: transparent;
                    }
                }
                @include break-sm{
                    border: 1px solid;
                }
            }
        }

        &.is-selected{
            position: relative;
            z-index: 11;
        }

        td{
            @include break-sm{
                width: 26.3%;
                border-color: transparent !important;
            }
        }

        @include break-sm{
            border: 1px solid;
            display: inline-block;
            width: 100%;
            margin-top: -1px;
        }

    }
    .td-account,
    .td-date,
    .t-amount{
        @include break-sm{
            display: inline-block;
        }
    }

    .td-label{
        display: none;
    }


    .list-group-item{
        font-size: inherit;
        border-top: none;
        border-bottom: none;
        padding: 10px 0;
    }

    .list-group{
        border-top: none;
        border-bottom: none;
    }

    .order-details{
        background-color: $background-color;
    }
}