/*######################################################
	confirmation page
#######################################################*/
.receipt-detail {
	padding: 6px;
	padding-bottom: 20px;
}

.recdet-label {
	display: block;
	font-size: 1.4em;
	line-height: normal;
	font-weight: 500;
	margin-top: 16px;

	.recdet-detail &{
		@include break-sm{
			display: block;
		}
	}

	@include break-sm{
		display: inline-block;
	}
}

.recdet-value {
	display: block;
	font-size: 1.5em;
	line-height: 1.4em;

	.recdet-amount &{
		@include break-sm{
			display: block;
			font-size: 2.2em;
		}
	}

	.recdet-detail &{
		@include break-sm{
			display: block;
		}
	}

	@include break-sm{
		display: inline-block;
	}
}

.recdet-divider {    
	border: none;
    border-top: 2px solid;
    height: 0;
    margin: 12px 0;
}

.recdet-panellabel {
	padding-bottom: 30px;
	padding-top: 20px;
}

.receipt-forward {
	padding: 30px 40px;
	margin: 10px 0;

	@include break-md {
		padding: 20px 30px;
	}

	@include break-sm {
		padding: 10px 20px;
	}

	@include break-xs {
		padding: 10px;
	}
}

.recdet-amount{
	text-align: right;

	@include break-sm{
		text-align: left;
	}
}
