/*######################################################
	welcome banner widget
#######################################################*/
.welcome-banner {
    background-color: $color-primary;
    color: $color-secondary;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top:50px;
    margin-bottom: 30px;
    text-align: center;
}

.welbann-icon {
	font-size: 2.5rem;
	margin: 5px auto;
}