/*######################################################
	summary flat
#######################################################*/
.smryflt-balance {
	border-collapse: collapse;
	display: table;
	margin-bottom: 20px;
	table-layout: fixed;
	width: 100%;
}

.smryflt-bal-col {
	display: table-cell;
	padding-bottom: 16px;
	padding-top: 16px;
	vertical-align: middle;
}

.smryflt-bal-name  {
	text-align: right;
    padding-right: 10px;

	p {
		margin: 0; 

		span {		
			line-height: 1;
		}
	}
}

.smryflt-bal-actions  {
	text-align: right;
	width: 132px;
}

.smryflt-item {
	color: #fff;
	margin-bottom: 30px;

	a {
    	color: #fff;
    	text-decoration: underline;

    	&:hover,
    	&:focus,
    	&:active {
    		color: #fff;
    		text-decoration: none;
    	}
    }

    p {    	
		font-size: 1em;
		line-height: 1.5em;
		margin-top: 10px;
		margin-bottom: 10px;
    }
}

.smryflt-content {
	@include clearfix;
}

.smryflt-inner {
	border-collapse: collapse;
	display: table;
	table-layout: fixed;
	width: 100%;
}

.smryflt-col {
	display: table-cell;
	padding: 15px 18px;
	vertical-align: middle;
	height: 71px;
}

.smryflt-col-name {
	@include break-ss(true) {
		width: 172px;
	}
}

.smryflt-col-actions {
	text-align: right;
}

.smryflt-title {
	font-size: 1.6rem;
}

.smryflt-exclamation {
	font-size: 1.4rem;
	padding-left: 6px;
}

.smryflt-questionmark {
	text-decoration: none !important;
}