date-picker, date-time-picker {
    // .date-picker{
    //     margin: 0 10px;
    // }
    .field-wrap {
        position: relative;
    }

    .date-picker-icon {
        position: absolute;
        font-size: 22px;
        top: 11px;
        margin-left: 10px;
        z-index: 11;
        color: $color-primary;
    }

    .date-input {
        font-size: initial;

        &[type='date'], &[type='datetime-local'] {
            padding: 10px 10px 10px 35px;
            position: relative;
            line-height: inherit;

            &::-webkit-inner-spin-button {
                position: absolute;
                left: 0;
                opacity: 0;
                background-color: transparent;
                z-index: 12;
            }

            &::-webkit-calendar-picker-indicator {
                position: absolute;
                left: 0;
                opacity: 0;
                background-color: transparent;
                z-index: 12;
                width: 40px;
                height: 40px;
            }
        }
    }

    &.date-picker-sm {
        .date-picker-icon {
            font-size: 16px;
        }

        .date-input {
            font-size: 1.3em;

            &[type='date'], &[type='datetime-local'] {
                padding: 10px 10px 10px 30px;
                line-height: 1.1;
            }
        }
    }

    &.adv-search {
        .date-picker {
            margin: 0 10px;
        }
    }
}
