/*######################################################
	account page
#######################################################*/

.account-btnapply{
    min-height: 51px;
    width: 100%;
}
.smbxs-label-summary {
    font-size:15px;
    line-height:15px;
    margin-top:10px;
}
.smbxs-label-value {
    font-size: 15px;
    line-height: 15px;
    margin-top: 10px;
}
.smbxs-label-summary-custom a{
    color:white;
}