﻿/*######################################################
    login page
#######################################################*/
.login {
    padding-top: 150px;

    @include break-sm {
        @include  is-portrait {
            padding-top: 70px;
        }

        @include  is-landscape {
            padding-top: 40px;
        }
    }

    @include break-xs {
        @include  is-portrait {
            padding-top: 40px;
        }
    }

    &-header {
        margin-bottom: 30px;
        text-align: center;
        padding-bottom: 30px;
        border-bottom: 2px solid;

        img{
            max-width: 340px;
        }
    }
    &-content {
        h1 {
            font-size: 1.8em;
            text-transform: uppercase;
            text-align: center;
            margin: 20px 0;
 
        }
        .form-control {
            margin: 0 0 10px 0;
        }
    }
    &-wrapper {
        width: 450px;
        margin: 0 auto;
        padding: 40px;
        background: #ffffff;

        @include break-xs {
            width: 100%;
        }
    }
    &-label {
        font-size: 1.6em;
        font-weight: normal;
    }
    &-footer {
        padding-top: 20px;
      
        .login-footer-link {
            font-size: 1.6em;
        }
    }


}