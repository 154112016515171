/*######################################################
	account message
#######################################################*/
.account-message {
	@extend .content-wrapper;
	margin-bottom: 40px;
	padding-right: 60px;
	position: relative;
	text-align: center;

	p {
		color: #fff;
		display: inline-block;
		margin: 0;
		margin-bottom: 1em;

		+ .btn {
			margin-left: 10px;
		}
	}
}

.accmsg-btn {
	@include border-radius(5px);
	background-color: #fff;
	padding: 4px 20px;
}

.accmsg-close {
	@include transition(opacity linear .2s);
	color: #fff;
    font-size: 4rem;
    font-weight: 100;
    opacity: 1;
    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -22px;
    text-shadow: none;

    &:hover,
    &:focus,
    &:active {
    	color: #fff;
    }
}