/*######################################################
  invoices and payments page
#######################################################*/
.invoicestabwrap {

    .nav-tabs{
        @include break-sm {
            display: none;
        }
    }

    .tabdrop-activelabel {
        font-size: 20px;
    }
}

.invoicestab {
    display: block;
    border-bottom: 1px solid;
    padding: 0;
    position: relative;
    margin: 50px 0 0 0;
    list-style: none;
    width: 100%;

    > li {
        cursor: pointer;
        display: inline-block;
        padding: 0;
        position: relative;
        text-align: center;

        > a {
            border-bottom: 6px solid transparent;
            display: block;
            font-size: 20px;
            line-height: 1;
            padding: 0 30px 40px 30px;
            text-decoration: none;

            @include break-md {
                padding: 0 20px 40px 20px;
            }
        }
    }
}

#account-details {
    .change-link {
        margin-top: 10px;
    }

    .account-select {
        margin-top: 20px;
    }

    .cancel-link {
        margin-left: 5px;
    }
}

#current-invoices {
        select[multiple] {
        border: none;
        float:left;
    }

    .th-duedate{
        min-width:110px;
    }
}


.tblwrap-select {
    float:left;
    text-align:left;
    padding:5px 0;        

    @include break-xs {
        margin-left:10px;
    }

    .checkbox {
        display:inline-block;
        vertical-align:bottom;
        margin-right:8px;

        > label{            
            line-height: 22px;
            font-weight: 400;

            @include break-sm {
                padding-left: 28px;
            }
        }

        @include break-sm {
            font-size: 13px;
        }

        @include break-xs {
            margin-bottom:7px;
        }
    }
}

.checkbox.is-bgactive {
    > input:checked ~ label:before{
        color: #fff;         
    }
}


#tableCurrentInvoices {
    .td-action {
        .dropdown-menu {
            top: 19px;
        }
    }
}

.invoicestabwrap-navtabs {
    list-style: none;
    margin: 20px 0 0;
    padding: 0;
    display: none;

    @include break-sm {
        display: block;
        @include transition(max-height linear .3s);
        max-height: 0;
        overflow: hidden;
    }

    .invoicestabwrap-tabsmobile.open & {
        @include break-sm {
            max-height: 300px;
        }
    }
}

.invoicestabwrap-navitemtab{
    display: block;
}

.invoicestabwrap-navlinktab{
    font-size: 2em;
    padding: 0 30px 20px 10px;
    display: block;

    &:hover, &:focus, &:active{
        text-decoration: none;
    }
}

/*------------------------------------------------------
    invoices and payment list
-------------------------------------------------------*/
.invoicesandpaymentlistwrap {
    padding-bottom: 20px;

    @include break-lg {
        padding-bottom: 60px;
    }

    @include break-md {
        padding-bottom: 100px;
    }
}

.invoicesandpaymentlist {
    @include clearfix;
    margin-top: 20px;
}

/*------------------------------------------------------
    invoice balance 
-------------------------------------------------------*/
.invbal-row {
    @include break-lg {
        @include clearfix;
        display: block;
        margin-bottom: -20px;
    }
}

.invbal-col {
    @include break-lg {
        padding-bottom: 20px;
        display: block;
        float: left;
        width: 50%;
    }

    @include break-sm {
        width: 100%;
    }
}

.invbal-btn-paynow {
    margin-bottom: 10px;

    @include break-lg {
        margin-right: 10px;
    }
}

.invbal-col-total {

}

.invbal-col-actions {
    @include break-lg (true) {
        text-align: center;
        width: 236px;
    }
}
.partpay-col {
    @include break-lg (true) {
        width: 100px;
    }
}
.paynow-col {
    @include break-lg (true) {
        width: 130px;
    }
}
.invbal-col-overdue {
    @include break-lg (true) {
        border-left: 1px solid;
        padding-left: 40px;
        width: 300px;
    }

    @include break-lg {
        border-top: 1px solid;
        padding-top: 10px;
        clear: both;
    }
}

.invbal-col-current {
    @include break-lg (true) {
        border-left: 1px solid;
        padding-left: 40px;
        width: 250px;
    }

    @include break-lg {
        border-top: 1px solid;
        padding-top: 10px;
    }
}

.invbal-icon-exclamation {
    margin-right: 10px;
    position: relative;
    top: 2px;
}


/* filter search */
.invpay-col-viewing {
    @include break-md(true) {
        width: 360px;
    }
}

.invpay-col-status {
    @include break-md(true) {
        width: 420px;
    }
}

.invpay-col-export {
    @include break-md(true) {
        width: 152px;
    }
}

/*------------------------------------------------------
    invoice and paymeny
-------------------------------------------------------*/
.invpay-col-export-dropdown {
    .dropdown-menu {
        @include break-lg {
            right: inherit;
            left: 0;
        }
    }
}

/*------------------------------------------------------
    invoice
-------------------------------------------------------*/
.invc-row {
    
    @include break-sm {
        @include clearfix;
        display: block;
        margin-bottom: -20px;
        overflow: hidden;
        table-layout: inherit;
    }
    

    &.invc-row-level1 {
        @include break-md {
            @include clearfix
            display: block;;
        }
    }

    &.invc-row-level2 {
        @include break-md {
            @include clearfix;
            display: block;
        }
    }
}

.invc-col {
    @include break-md {
        display: block;
        padding: 0 0 20px 0;
        float: left;
        padding-bottom: 10px;
    }

    @include break-sm {
        .invc-row-level2 & {
            width: 50%;
        }
    }

    @include break-ss {
        .invc-row-level2 & {
            width: 100%;
            padding-left: 0;
        }
    }
}

.invc-identity {
    @include break-md (true) {
        width: 200px;
    }

    @include break-md {
        display: block;
        float: left;
        width: 40%;
    }

    
    @include break-xs {
        float: none;
        width: 100%;

    }
    

    .invc-row.invc-row-level2 {
        @include break-md {
            display: table;
        }

        @include break-xs {
            display: block;
        }

        .invc-col {
            @include break-md {
                display: table-cell;
                float: inherit;
            }

            @include break-sm {
                width: auto;

                &.invc-col-checkbox {
                    width: 50px !important;
                }
            }

            @include break-xs {
                display: block;
                width: 100%;

                &.invc-col-checkbox {
                    text-align: center;
                    width: 100% !important;
                }
            }
        }
    }
}

/*
.invc-values {
    @include break-lg {
        display: block;
        float: left;
        width: 60%;        
    }

    @include break-sm {
        float: none;
        width: 100%;

    }

    .invc-row-level2 {
        @include break-lg {
            display: table;
        }
        @include break-md {
            display: block;
        }
    }
}
*/

/*
.invc-actions {    
    @include break-lg (true) {
        width: 384px;
    }

    @include break-lg {
        clear: both;
        display: block;
        width: 100%;

        .invc-row {
            display: block;
            table-layout: inherit;
            width: 100%;
        }
    }
}
*/

.invc-col-checkbox {
        width: 30px;
        text-align: left;

        @include break-sm {
            width: 30px !important;
            padding-top: 20px;
        }

         @include break-ss {
            width: 100% !important;
            text-align: center;
         }

        span {
            display: inline-block;
            line-height: 22px;
            text-align: center;
        }
}

.invc-col-number {
    padding-left: 10px;
    @include break-lg (true) {
        padding-right: 20px;
        width: 137px;
    }

    @include break-lg {
        width: inherit;
    }
}

.invc-col-amount {
    @include break-md(true) {
        padding-left: 40px;
        border-left: 1px solid;
    }
    
    @include break-md {
        width: 50%;
        padding-left: 20px;
    }

    @include break-sm {
        padding-top: 10px;
    }

    @include break-ss {
        padding-top: 0;
    }

    @include break-xs {
        float: inherit;
        padding-left: 0;
        width: 100%;
    }

}

.invc-col-actions {
    @include break-lg (true) {
        width: 300px;
    }

    @include break-lg {
        width: 190px;
    }

    @include break-md {
        border-top: 1px solid;
        clear: both;
        display: block;
        float: left;
        padding-top: 20px;
        width: 40%;
    }

    @include break-sm (true) {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }

    @include break-sm {
        border-top: none;
        padding-top: 0;
    }  

    @include break-xs {
        float: inherit;
        width: 100%;
    }   

    .panel-row {
        @include break-lg {
            display: block;
        }
    }

    .panel-column {
        @include break-lg {
            display: block;

            &:not(:last-child) {
                padding-bottom: 10px;
            }
        }
    }


    .btn {
        @include break-lg(true) {
            float: left;
        }
    }
    
}

.invc-col-services {
    @include break-md (true) {
        padding-left: 40px;
        width: 240px;
    }

    @include break-md {
        display: block;
        float: left;
        padding-left: 20px;
        width: 60%;
    }

    @include break-md (true) {
        border-left: 1px solid;
    }

    @include break-md {
        border-top: 1px solid;
        padding-top: 20px;

    }

    @include break-sm {
        border-top: none;
        padding-top: 10px;
    }

    @include break-ss {
        padding-top: 0;
    }

    @include break-xs {
        float: inherit;
        padding-left: 0;
        width: 100%;
    }
}



.invc-paynow {
    @include break-lg (true) {
        text-align: center;
    }

    @include break-md (true) {
        //width: 224px;
    }
}

.invc-viewdetails {
    @include break-lg (true) {
        width: 150px;
    }

    @include break-md {
        display: block;
        float: left;
        width: 40%;
    }


    @include break-xs {
        float: none;
        width: 100%;
    }
}

.invc-btn-paynow {
    padding-left: 36px;
    padding-right: 36px;

    @include break-sm {
        margin-bottom: 0;
    }
}

.bucket-pay-now {
    font-size: 0.7em;
    text-decoration: underline;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

#invoice-tab{
    .table-grid .nav-tabs > li > a {
        padding: 0 25px 30px 25px;
    }
	
}
.linkback{
    font-size:1.9em;
    margin-bottom: 0px;
    margin-top: 30px;
}

.invoice-tab-2{
    .is-tabvar-2 .nav-tabs > li > a{
        height: 105px;
        text-align: center;
    }
}
.package-col-summary {
    @include break-lg (true) {
        border-left: 1px solid;
        padding-left: 40px;
        border-left-color: #dfdfdf;
    }

    @include break-lg {
        border-top: 1px solid;
        padding-top: 10px;
        clear: both;
    }
}

.packagesumm-row {
    border-bottom: 1px solid;
    padding-bottom: 10px;
    padding-top: 10px;
}
.packagesumm-last-row {
    padding-top: 10px;
}
.packagebal-row {
    padding-bottom: 15px;

    > a {
        padding-bottom: 7px;
    }
}

/*------------------------------------------------------
    Promise and Dispute
-------------------------------------------------------*/
.icon-exclamation-dispute{
    color:red;
}
.icon-exclamation-promise {
    color: green;
}