/*######################################################
	quick action
#######################################################*/
.quickaction {
    margin-bottom: 40px;
    padding: 22px 40px;
}

.qckact-title {
    text-transform:uppercase;
    font-size: 1.4em;
    font-weight: 600;
    width:135px;
    float:left;
    line-height: 26px;
}

.qckact-list {
    @extend .list-unstyled;
    font-size: 1.5em;
    font-weight: 500;
    text-align:left;
    margin-bottom:0;

    > li {
        display:inline-block;
        margin-right:27px;

        > a {
            color: #fff;
            line-height:26px;
            transition: color ease-in 0.2s;

            &:hover{
                text-decoration:none;
            }

            > span {
                margin-right:7px;
                display:inline-block;

                &.icon-phone {
                    font-size: 1.4em;
                    vertical-align: middle;
                    margin-right: 2px;
                    margin-top:-2px;                       
                }
                &.icon-send-login {
                    font-size: 1.3em;
                    vertical-align: middle;
                    margin-top: -3px;
                    margin-right: 5px;
                }
            }
        }            
    }
}


.next-task-title {
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}