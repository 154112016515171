/*######################################################
	typography
#######################################################*/
header,
.main-content,
 footer {
	@include transition(transform .3s ease-in);

	.is-sidebaropen & {
		@include transform(translateX(-250px));
	}
}


.main-content {
	padding-top: 0;
	position: relative;
	z-index: 0;
	
	&.is-fixed {
		padding-top: 206px;
	}
}

strong {
	font-weight: 700;
}

p {
    font-size: 1.5em;
}

dl {
	margin: 0;
	overflow:hidden;
	width:100%;

	dt {
		clear: both;
		float:left;
		padding: 5px 0 0 0;
		text-align: left;
		width:45%;
	}

	dd {
		float:left;
		padding: 5px 0 0 0;
		text-align: left;
		width:55%; 
	}
}

a.disabled{
    pointer-events: none;
    cursor: default;
    color: $gray-lighter;
}

.semibold {
	font-weight: 600 !important;
}

.bold {
	font-weight: 700 !important;
}

.normal {
	font-weight: 500 !important;
}

.light {
	font-weight: 400 !important;
}

.basic-fontstyle {
	font-size: 1.5em;
	font-weight: 500;
	line-height: 1.4em;
}

.wysiwyg-section {
	font-size: 1.5em;
	font-weight: 500;
	line-height: 2em;

	h5 {
		font-weight: 700;
		font-size: .8em;
	}

	p {
		font-size: 1em;
	}
}

.page-label {
	font-size: 4em;
	line-height: normal;
	margin: 0;
	padding: 0;
	text-transform: capitalize;
	@include break-xs {
		font-size: 3.5em;
	}
}

.page-sublabel {
	margin-top: 30px;
	margin-bottom: 30px;
}


.content-wrapper {
	background-color: #fff;
	padding: 30px 40px;

	~ .content-wrapper {
		margin-top: 40px;
	}

	@include break-md {
		padding: 20px 30px;
	}

	@include break-sm {
		padding: 15px 20px;
	}

	@include break-xs {
		padding: 10px;
	}
}

.popover{
	color:#000;
}

.borderless {
	border: none !important;
}

.borderbot2 {
	border-bottom: 2px solid $border-color !important;
}

.marginless {
	margin: 0 !important;
}

.padleftless {
	padding-left: 0 !important;
}

.padrightless {
	padding-right: 0 !important;
}

.divider {
	border-top: 1px solid $border-color;
	height: 1px;
	margin-bottom: 30px;
	margin-top: 35px;
}

.va-middle {
	vertical-align: middle !important;
}

.disc-font{
	font-family:"discfont";
	
}

/*######################################################
	color
#######################################################*/
.cl-primary{
	color: $brand-primary;
}

.cl-success{
	color: $brand-success;
}

.cl-danger{
	color: $brand-danger;
}

.cl-gray{
	color: $gray;
}

.cl-graylight {
	color: $gray-light;
}

.cl-graylighter {
	color: $gray-lighter;
}


/*######################################################
	titles
#######################################################*/
.t-lg {
	font-size: 4em;
	font-weight: 500;
	line-height: normal;
}

.t-md {
	font-size: 3.4em;
	font-weight: 500;
	line-height: normal;
}

.t-sm {
	font-size: 2em;
	font-weight: 500;
	line-height: 1.6em;
}

.t-xs {
	font-size: 1.8em;
	font-weight: 500;
	line-height: normal;
}



/*######################################################
	paragraph
#######################################################*/
.p-lg {
	font-size: 2em;
	line-height: 1.8em;
}

.p-md {
	font-size: 1.8em;
    line-height: 1.8em;
}

.p-sm {
	font-size: 1.5em;
	line-height: 2em;
}

.p-xs {
	font-size: 1.3em;
	line-height: 2.2em;
}

/*######################################################
	heading
#######################################################*/
.h-xlg {
	font-size: 4.4rem !important;
	font-weight: bold;
	line-height: normal;
}


.h-lg {
	font-size: 4rem !important;
	font-weight: bold;
	line-height: 61px;
}

.h-md {
	font-size: 3rem !important;
	font-weight: bold;
	line-height: 32px;
}

.h-sm {
	font-size: 2.6rem !important;
	font-weight: bold;
	line-height: 2em;
}

.h-xs {
	font-size: 2.2rem !important;
	font-weight: bold;
	line-height: 1.6em;
}

.h-xxs {
	font-size: 2rem !important;
	font-weight: bold;
	line-height: 1.3em;
}