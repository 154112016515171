@mixin breaktext(){
	white-space: pre-wrap;       /* css-3 */
	white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
	white-space: -pre-wrap;      /* Opera 4-6 */
	white-space: -o-pre-wrap;    /* Opera 7 */
	word-wrap: break-word;  
}

@mixin ellipsis(){
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

@mixin border-radius($radius){
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
          overflow: hidden;
}

@mixin transition($transition, $transition2:false, $transition3:false ){
    @if( $transition2 or $transition3  ) {  

        @if( $transition2 and $transition3 == false  ) {  
            transition: $transition, $transition2;
            -webkit-transition: $transition, $transition2;
            -moz-transition: $transition, $transition2;
            -o-transition: $transition, $transition2;
        }

        @if( $transition2 and $transition3  ) {  
            transition: $transition, $transition2, $transition3; 
            -webkit-transition: $transition, $transition2, $transition3;
            -moz-transition: $transition, $transition2, $transition3;
            -o-transition: $transition, $transition2, $transition3;
        }

    } @else {        
        transition: $transition;
        -webkit-transition: $transition;
        -moz-transition: $transition;
        -o-transition: $transition;
    }
}

@mixin box-shadow ($boxshadow){
	box-shadow: $boxshadow;
	-webkit-box-shadow: $boxshadow;
	-moz-box-shadow: $boxshadow;
	-o-box-shadow: $boxshadow;
}

@mixin transform($transform) {
	-moz-transform: $transform;
	-ms-transform: $transform;
	-o-transform: $transform;
	-webkit-transform: $transform;
	transform: $transform;
    -webkit-backface-visibility: hidden;
}

@mixin extend-icomoon (){
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


@mixin color-variant( $variable:false, $single:false ) {
    $listColour: $darkish-purple $turtle-green $slate  $brownish-orange $lipstick $leaf $dark-orange $teal $london-hue $dark-cerulean;
    $count: 1;

    @each $colour in $listColour {
        @if( $variable ) {  
            @if( $single ) {  
                .is-#{$variable}-colour-#{$count} & {    
                    color: $colour;
                }
            } @else {
                 [class^="is-colour-"] .is-#{$variable}-colour-#{$count} & {    
                    color: $colour;
                }
            }

        } @else {
            .is-colour-#{$count} & {    
                color: $colour;
            }
        }

        $count : $count + 1;
    }
}

@mixin border-variant( $variable:false ) {
    $listColour: $darkish-purple $turtle-green $slate  $brownish-orange $lipstick $leaf $dark-orange $teal $london-hue $dark-cerulean;
    $count: 1;

    @each $colour in $listColour {
        @if( $variable ) {  
            [class^="is-colour-"] .is-#{$variable}-colour-#{$count} & {    
                border-color: $colour;
            }
        } @else {
            .is-colour-#{$count} & {    
                border-color: $colour;
            }
        }

        $count : $count + 1;
    }
}

@mixin background-variant( $opacity:false, $variable:false ) {
    $listColour: $darkish-purple $turtle-green $slate  $brownish-orange $lipstick $leaf $dark-orange $teal $london-hue $dark-cerulean;
    $count: 1;

    @each $colour in $listColour {
        @if( $variable ) {  
            [class^="is-colour-"] .is-#{$variable}-colour-#{$count} & {    
                @if( $opacity ) {  
                    background-color: rgba($colour, $opacity);
                } @else {
                    background-color: $colour;
                }
            }
        } @else {
            .is-colour-#{$count} & {  
                 @if( $opacity ) {  
                    background-color: rgba($colour, $opacity);
                } @else {
                    background-color: $colour;
                }
            }
        }

        $count : $count + 1;
    }
}

@mixin bghover-variant( $opacity:false, $variable:false ) {
    $listColour: $darkish-purple $turtle-green $slate  $brownish-orange $lipstick $leaf $dark-orange $teal $london-hue $dark-cerulean;
    $count: 1;

    @each $colour in $listColour {
        @if( $variable ) {  
            [class^="is-colour-"] .is-#{$variable}-colour-#{$count} & {    
                background-color: $colour;
                &:hover,
                &:focus,
                &:active {
                    background-color:  lighten($colour, $opacity);
                }
            }
        } @else {
            .is-colour-#{$count} & {    
                background-color: $colour;
                &:hover,
                &:focus,
                &:active {
                    background-color:  lighten($colour, $opacity);
                }
            }
        }
        
        $count : $count + 1;
    }
}



@mixin background-image( $opacity:false, $variable:false ) {
    $listColour: $darkish-purple $turtle-green $slate  $brownish-orange $lipstick $leaf $dark-orange $teal $london-hue $dark-cerulean;
    $count: 1;

    @each $colour in $listColour {
        @if( $variable ) {  
            [class^="is-colour-"] .is-#{$variable}-colour-#{$count} & {    
                background-image: linear-gradient(to right, $colour, $colour $opacity, transparent $opacity, transparent);
            }
        } @else {
            .is-colour-#{$count} & {    
                background-image: linear-gradient(to right, $colour, $colour $opacity, transparent $opacity, transparent);
            }
        }
        
        $count : $count + 1;
    }
}




// Media Query break points
@mixin break-xs ( $isMin:false ) {
    
    @if( $isMin == true ) {
        @media only screen and (min-width: $screen-xs + 1) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-xs-min) {
            @content;
        }
    }
}

@mixin break-ss( $isMin:false ) {
    
    @if( $isMin == true ) {
        @media only screen and (min-width: 600px) {
            @content;
        }
    } @else {

        @media only screen and (max-width: 599px) {
            @content;
        }
    }
}

@mixin break-sm( $isMin:false ) {
    
    @if( $isMin == true ) {
        @media only screen and (min-width: $screen-sm) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-xs-max) {
            @content;
        }
    }
}

@mixin break-md( $isMin:false ) {
    
    @if( $isMin == true ) {
        @media only screen and (min-width: $screen-md) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-sm-max) {
            @content;
        }
    }
}

@mixin break-lg( $isMin:false ) {
    @if( $isMin == true ) {
        @media only screen and (min-width: $screen-lg) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-md-max) {
            @content;
        }
    }
}

// targeting browser
@mixin is-firefox() {
    @-moz-document url-prefix() { 
        @content;
    }
}

@mixin is-chrome(){
    @media screen and (-webkit-min-device-pixel-ratio:0) {
        @content;
    }
}

@mixin is-ie(){
    /* IE10+ */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@mixin is-safari(){
    /* Safari 6.1-10.0 (10.1 is the latest version of Safari at this time) */
    @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) { 
        @media {
            @content;
        }
    }
}

// rotating screen
@mixin is-portrait() {
    @media screen and (orientation:portrait) {
       @content;
    }
}

@mixin is-landscape() {
    @media screen and (orientation:landscape) {
       @content;
    }
}
