/*######################################################
	task page
#######################################################*/
#task-list {
    .tblwrap-ovr-button {
        width: 210px;
    }
}
.attachment-list {
    position: relative;

    .table-grid .nav-tabs > li.active a {
        border-bottom: 1px solid $border-color !important;
    }

    .table-grid .nav-tabs > li > a {
        padding-left: 0
    }
}
.attachment-button {
    position: absolute;
    right: 0;
    top: -5px;

    .btn-add-attachment {
        padding: 12px 15px;
    }
}

