/*######################################################
  form
#######################################################*/
.form-wrapper {
    padding: 30px 0;

    .modal-body & {
        padding: 0;
    }
}

.form-group {
    @include clearfix;
    margin-bottom: 10px;
    .modal & {
        margin-bottom: 20px;
    }
}

.form-item {
    position: relative;
}

.control-label {
    font-size: 1.5em;
    font-weight: 600;
    line-height: normal;
    .is-thin & {
        padding-top: 4px;
    }
}

.form-control {
    @include transition(all ease-in .2s);
    @include box-shadow(none);
    display: inline-block;
    font-size: 1.5em;
    font-weight: 500;
    height: inherit;
    line-height: normal;
    padding: 14px 16px;
    width: 100%;

    .is-tiny & {
        padding: 2px 6px;
    }
    .is-thin & {
        padding: 4px 10px;
    }
    &:focus {
        @include box-shadow(none);
    }

    &:disabled,
    &[disabled="disabled"] {
        cursor: default;
    }      
}

.form-control-static {
    font-size: 1.5em;
    font-weight: 500;
    line-height: normal;
    padding: 0;
}

.form-field-sm {
    max-width: 178px;
    width: 100%;
}

.form-field-xs {
    max-width: 100px;
    width: 100%;
}

.form-field-xxs {    
    max-width: 80px;
    width: 100%;
}

.form-field-overlay {
    text-align: right;
}

.form-item {
    padding-bottom: 10px;
}

.form-message {
    font-size: 1.3em;
    padding: 5px 0;
    
    &.error {
        //color: $brand-danger; color is belong to skin's property
    }
}

textarea.form-control {
    padding: 16px 20px;
    resize: vertical;
    max-width: 100%;
    .modal & {
        min-height: 180px;
    }
    .is-thin & {
        padding: 6px 10px;
        height: 60px;
    }
}

.is-datepicker {
    position: relative;
    &:before {
        @include extend-icomoon;
        @extend .icon-calendar;
        position: absolute;
        right: 20px;
        top: 18px;
        font-size: 1.6em;

        .modal &{
            top:18px;
        }
    }

    input[type="date"].form-control{
        line-height: normal;
        padding: 13px 16px;

        &::-webkit-inner-spin-button {
            opacity: 0;
        }

        &::-webkit-calendar-picker-indicator {
            opacity: 0;
        }

        .modal &{
            line-height: normal;
            padding: 13px 16px;
        }
    }
}

label {
    .modal &, .details-view & {
        font-size: 16px;
        font-weight: 500;
    }
}

legend {
    border: none;
}

.input-group {
    border-collapse: separate;
    display: table;
    position: relative;
}

.input-group-addon:first-child {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group-addon {
    background-color: #fff;
    border: 1px solid;
    border-right: none;
    border-radius: 4px;
    display: table-cell;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    padding: 6px 12px;
    position: relative;
    right: -3px;
    text-align: center;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle; 
}