/*######################################################
	reset
#######################################################*/
html,
body{
    margin:0;
    padding:0;

    @include break-sm {
      font-size: 9.5px;
    }

    @include break-ss {
      font-size: 9.2px;      
    }

    @include break-xs {
      font-size: 9px;         
    }
}

body {
  background-color: #f9f9f9;
  height: 100vh;
  min-height: 100vh;
  font-weight: 500;

  &.is-hasbodybg {
    background-repeat: no-repeat;
    background-position:  center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}


html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-fluid {
	max-width: 1180px;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  box-shadow: none;
  outline: 0 none;
}