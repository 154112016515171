/*------------------------------------------
	font-face icomoon
------------------------------------------*/
@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon/icomoon.eot?l7c6n0');
    src:    url('../fonts/icomoon/icomoon.eot?l7c6n0#iefix') format('embedded-opentype'),
        url('../fonts/icomoon/icomoon.ttf?l7c6n0') format('truetype'),
        url('../fonts/icomoon/icomoon.woff?l7c6n0') format('woff'),
        url('../fonts/icomoon/icomoon.svg?l7c6n0#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-bitcoin:before {
    content: "\e900";
  }
  .icon-paypal:before {
    content: "\e901";
  }
  .icon-home:before {
    content: "\e902";
  }
  .icon-user:before {
    content: "\e903";
  }
  .icon-pagination-right:before {
    content: "\e904";
  }
  .icon-calendar:before {
    content: "\e905";
  }
  .icon-addnew:before {
    content: "\e906";
  }
  .icon-attachment:before {
    content: "\e907";
  }
  .icon-bank-transfer:before {
    content: "\e908";
  }
  .icon-bpay:before {
    content: "\e909";
  }
  .icon-plus-thin:before {
    content: "\e90a";
  }
  .icon-chevron-dropdown:before {
    content: "\e90b";
  }
  .icon-close:before {
    content: "\e90c";
  }
  .icon-creditcard:before {
    content: "\e90d";
  }
  .icon-direct-debit:before {
    content: "\e90e";
  }
  .icon-ellipses:before {
    content: "\e90f";
  }
  .icon-exclamation:before {
    content: "\e910";
  }
  .icon-mainmenus:before {
    content: "\e911";
  }
  .icon-notes:before {
    content: "\e912";
  }
  .icon-quick-actions:before {
    content: "\e913";
  }
  .icon-search:before {
    content: "\e914";
  }
  .icon-plus:before {
    content: "\e915";
  }
  .icon-arrow-up-right:before {
    content: "\e916";
  }
  .icon-arrow-down-left:before {
    content: "\e917";
  }
  .icon-tasks:before {
    content: "\e918";
  }
  .icon-tick:before {
    content: "\e919";
  }
  .icon-pagination-left:before {
    content: "\e91a";
  }
  .icon-envelope:before {
    content: "\e91b";
  }
  .icon-comment:before {
    content: "\e91c";
  }
  .icon-letter:before {
    content: "\e91d";
  }
  .icon-delete:before {
    content: "\e91e";
  }
  .icon-trash:before {
    content: "\e91f";
  }
  .icon-phone:before {
    content: "\e920";
  }
  .icon-autopay:before {
    content: "\e921";
  }
  .icon-address:before {
    content: "\e922";
  }
  .icon-account-phone:before {
    content: "\e923";
  }
  .icon-account-name:before {
    content: "\e924";
  }
  .icon-account-email:before {
    content: "\e925";
  }
  .icon-questionmark:before {
    content: "\e926";
  }
  .icon-email:before {
    content: "\e90df";
  }
  .icon-send-login:before {
    content: "\10ffff";
  }
  .icon-hour-glass:before {
    content: "\e979";
  }
  