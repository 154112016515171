/*######################################################
	footer
#######################################################*/
footer {
    padding: 40px 0;

    span {
        opacity: 0.4;
    }

    @include break-sm {
    	padding: 20px 0;
    }
}

.footer-link {
    padding-bottom:10px;
    ul {
        list-style: none;
        display: block;
        margin: 0;
    }

    li {
        display: inline-block;
        padding: 0 0 0 10px;
    }
}
