/*######################################################
	content
#######################################################*/
/* part pay modal */
.partpaymodal-content {
	display: table;
	table-layout: fixed;
	width: 100%;

	@include break-xs {
		display: block;

		.pull-right {
			float: none !important;
		}
	}
}

.prpymdl-left,
.prpymdl-right {
	display: table-cell;
	vertical-align: top;

	@include break-xs {
		display: block;
		width: 100%;
	}
}

.prpymdl-left {
	border-right: 1px solid;
	width: calc((100%/5)*2);
	padding-right: 40px;

	@include break-xs {
		border:none;
		padding: 20px 0 0 0;
		width: 100%;
	}
}

.prpymdl-right {
	padding-left: 40px;

	@include break-xs {
		padding-left: 0;
	}
}

.prpymdl-listinfo {
	padding-top: 5px;
	@extend .list-unstyled;

	li {
		display: block;
		padding: 6px 0;
	}

	span {
		display: inline-block;
	}
}

.prpymdl-listinfo-value {
	padding-left: 5px;
}

.is-prpymdl-block {
	display: block;
	padding-left: 0;
}

.prpymdl-payamount-label {
	padding-top: 15px;
}

.prpymdl-form-wrap {
	padding: 0;
}


/* address panel */
.addresspanel {
	@include make-row;
	max-width: 540px;
	width: 100%;
}

.addp-col {
	@extend .col-xs-6;
	padding-bottom: 10px;

	.wysiwyg-section {
		max-width: 190px;
	}

	@include break-xs {
		width: 100%;
	}
}

.addp-title {
	font-size: 1.2em;
	font-weight: 700;
	line-height: normal;
}

.linkback {
	display: inline-block;
	font-size: 1.6em;
	line-height: 30px;
	margin-bottom: 30px;
	padding-left: 15px;
	position: relative;

	&:before {
		@include transform(rotate(90deg));
		@include transition(color .3s ease);
		@include extend-icomoon;
		@extend .icon-chevron-dropdown;
		font-size: 6px;
		font-weight: normal;
		position: absolute;
		left: 0;
		margin-top: -4px;
		top: 50%;
	}

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
}


/* button */
.btnwrap {
	text-align: right;
	position: relative;

	@include break-xs {
		text-align: center;
	}
}

/*######################################################
	drop content
#######################################################*/
.dropcontent-wrapper {
	position: relative;
}

.drpcnt-btn {
	border:none;
	display: none;
	font-size: 1.5em;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 10px;
	padding: 14px 30px 14px 0;
	position: relative;

	&:hover,
    &:active,
    &.focus,
	&:focus,
	&.active {
		background-color: transparent !important;
		outline: none;
	}

	.icon-chevron-dropdown {
		@include transition(transform .1s ease);
		display: inline-block;
		font-size: 8px;
		height: 14px;
		line-height: 14px;
		margin-top: -7px;
		position: absolute;
		right: 0;
		top: 50%;


		.is-dropcontent-open & {
			@include transform(rotate(-180deg));
		}
	}

	@include break-md {
		display: block;
	}
}

.drpcnt-content {
	@include transition(max-height linear .3s);
	@include clearfix;
	
	@include break-md {
		height: 100%;
		max-height: 0;
		overflow: hidden;

		.is-dropcontent-open & {
			max-height: 500px;
			overflow: visible;
		}

		> div {
			display: block;
			float: none;
		}
	}
}

/*######################################################
	list link
#######################################################*/
.list-link {
	list-style: none;
	line-height: normal;
	margin: 0;
	padding: 0;
}

.link {
	font-size: 1.6rem;
	display: inline-block;
	line-height: normal;

	.list-link & {
		padding: 3px 0;
	}
}
