/*######################################################
	checkbox
#######################################################*/

.checkbox {
    &.checkbox-inline {
        display: inline-block;
    }
    label {
        padding-left: 32px;
        &:before {
            @include border-radius(20%);
            background-color: #fff;
            border: solid 1px;
            content: "";
            cursor: pointer;
            display: block;
            font-size: 12px;
            font-weight: bold;
            height: 24px;
            left: 0;
            padding-top: 5px;
            position: absolute;
            text-align: center;
            top: 2px;
            visibility: visible;
            width: 24px;
        }
    }
    &.error {
        label {
            margin-bottom: 0;
        }
        .error-message {
            margin-bottom: 20px;
        }
    }
    input[type="checkbox"] {
        margin-left: 0;
        visibility: hidden;
        &:checked~label:before {
            @include extend-icomoon;
            @extend .icon-tick;
        }
    }
}

.radio,
.checkbox {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
    label {
        font-weight: normal;
        padding-bottom: 4px;
        padding-top: 4px;
    }
}

.checkbox {
    &:disabled,
    &[disabled="disabled"] {
        label::before {
            background-color: lighten($gray-lighter, 30%);
            border-color: $border-color;
            color: $gray-lighter;
            cursor: not-allowed;
        }

        label{
            cursor: not-allowed;
        }
    }
}