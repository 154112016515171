/*######################################################
	template page
#######################################################*/
#merge-fields {
    .mergefields-subnote {
        font-size: 1.2em;
    }
}

.template-page{
    .checkbox label{
        font-weight: normal;
        margin-bottom: 10px;
    }
}