.package-summary{
    .nav-container{
        margin-top:9em;

        @include break-sm{
            margin-top: 0;
        }
    }

    .prev-link{
        float: left;

        @include break-sm{
            float: none;
        }

    }

    .next-link{
        float: right;
        margin-right: 20px;

        @include break-sm{
            float: none;
            margin-right: 0;
        }
    }
}