/*######################################################
	header
#######################################################*/
header {
	background-color: #fff;
	padding: 20px 0;
	//position: fixed;
	//left: 0;
	//top: 0;
	width: 100%;
	z-index: 9;
	&.is-operator {
		position: static;
		padding: 10px 0;
	}
}

.logo-receipt {
	display: inline-block;
	max-width: 15em;
	float: left;
	padding-right: 20px;

	@include break-xs {
		max-width: 200px;
	}

	> img {
		@extend .img-responsive;
	}
}

.top-brand {
	display: inline-block;
	max-width: 254px;
    float: left;
    padding-right: 20px;
	@include break-xs {
		max-width: 200px;
	}

	> img {
		@extend .img-responsive;
	}
}

.top-menu-search {
	position: relative;
	float: left;
	border: 1px solid;
	border-radius: 3px;
	overflow: hidden;
	&-input {
		border: none;
		padding: 10px 35px 10px 15px;
		font-size: 1.5em;
	}
	.icon-search {
		position: absolute;
		right: 12px;
		font-size: 1.5em;
		top: 12px;
	}

}
.top-menu-main{
	&.is-open{
		@include break-sm{
			right: 0;
		}
	}

	@include break-sm{
		position: fixed;
		right: 0;
		height: 100%;
		top: 0px;
		padding: 70px 20px 20px;
		z-index: 999;
		width: 250px;
		overflow-y: auto;
		right: -250px;
		transition: right 0.3s ease-in;
	}
}