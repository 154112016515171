/* Base variables */
$screen-lg      : 1200px;

$screen-md-max  : $screen-lg - 1;
$screen-md      : 992px;
$screen-md-min  : $screen-md; 

$screen-sm-max  : $screen-md - 1;
$screen-sm      : 768px;
$screen-sm-min  : $screen-sm; 

$screen-xs-max  : $screen-sm - 1;
$screen-xs      : 480px;
$screen-xs-min  : $screen-xs; 

$border-color: #dfdfdf;
$border-secondary: #dde6fd;

$color-primary: #436dda;
$color-secondary: #f6fcff;
$color-tertiary : #74a901;
$color-quaternary: #56babc;

$color-success	: #5cb85c;
$color-info		: #5bc0de;
$color-warning	: #f0ad4e;
$color-danger	: #fb0d1b; //darken(#D66066, 10%);

$background-color: #f9f9f9;
$color-autopay: #2F5688;