/*######################################################
	operator task page
#######################################################*/

.invoicestab {
    .is-operator-tasks & {
        margin-top: 20px;
    }
}

.table-tasks {
    td {
        padding: 32px 10px;
    }
}

.account-tasks-page {
    .task-wrapper {
        margin-bottom: 2em;
    }
}