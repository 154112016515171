/*######################################################
	masthead
#######################################################*/
.masthead {
    padding-top: 40px;
    padding-bottom: 50px;
    .is-operator & {
        &.is-fixed {
            position: fixed;
            top: 0;
            width: 100%;
            background: #ffffff;
            padding: 20px 0;
            z-index: 1000;
            box-shadow: 0px 4px 10px 0px rgba(125, 125, 125, 0.3);
        }
    }
    @include break-sm {
        padding: 30px 0;
    }
}

.mstd-inner {
    border-collapse: collapse;
    display: table;
    table-layout: fixed;
    width: 100%;
    @include break-md {
        display: block;
    }
}

.mstd-label,
.mstd-actions {
    display: table-cell;
    vertical-align: top;
    @include break-md {
        display: block;
        width: 100%;
    }
}

.mstd-actions {
    text-align: right;
    width: 436px;
    > span {
        display: inline-block;
        font-size: 1.5em;
        float: left;
        padding-top: 14px;
        @include break-sm {
            display: block;
            float: none;
            padding-bottom: 10px;
            width: 100%;
        }
    }
    @include break-md {
        @include clearfix;
        padding-top: 15px;
        text-align: left;
    }
    @include break-xs {
        width: 100%;
    }
}

.mstd-actions-dropdown {
    float: right;
    position: inherit;

    @include break-sm {
        float: none;
        position: relative;
        width: 100%;
        margin: 15px 0;
    }
    .dropdown-toggle,
    .btn-default:active,
    .btn-default.active,
    &.open > .btn-default.dropdown-toggle {
        padding: 14px 20px;

        @include break-sm {
            padding-right: 35px;
        }
    }
    .dropdown-menu {
        left: auto;
        right: auto;
        top: auto;
        width: 100%;
        &.bounded {
            width: auto;
            max-height: 500px;
            max-width: 550px;
            overflow-y: auto;

        }

        > li{
            white-space: nowrap;
        }
        > li > a{
            white-space: normal;
        }

        @include break-sm{
            z-index: 21;
        }
    }
    .dropdown-toggle {
        @include break-sm {
            width: 100%;
            text-align: left;
            padding-right: 35px;
        }
        .caret {
            @include break-sm {
                position: absolute;
                right: 18px;
                top: 21px;
            }
        }
    }

    .masthead &{
        position: relative;
    }

    > button {
        > span:not(.caret){
            max-width: 500px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            margin-bottom: -6px;

            @include break-sm{
                width: 100%;
            }
        }
    }
}

.mstd-nav {
    clear: both;
    display: block;
    max-width: 950px;
    width: 100%;
    > ul {
        display: table;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        > li {
            display: table-cell;
            padding: 0 5px;
        }
    }
}

.mstd-link {
    @include transition(color ease-in .2s);
    display: inline-block;
    font-size: 1.4em;
    font-weight: 500;
    line-height: normal;
    padding: 20px 0 4px;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    .is-active & {
        border-bottom: 3px solid;
    }

    .badge {
        font-weight: 300;
        font-size:11px;
        margin-top: -2px;
    }
}

.mstd-next-row-button {
    margin-top: 3px;
}