﻿/*######################################################
	Contact details
#######################################################*/
.contactdetails{
    padding-top:30px;
}
.contactdetails-list{
    @extend .list-unstyled;    
    text-align:left;
    margin-bottom:0; 
    text-align:left;
    font-size:13px;
    font-weight:300;

    > li{
        display:inline-block;
        margin-right:20px;
    }
}

.contactdetails-icon{
    font-size:10px;
    margin-right:5px;

    &.icon-account-email{
        font-size:8px;
    }
    &.icon-account-name{
        font-size:9px;
    }
}
