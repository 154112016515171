/*######################################################
	summary boxes
#######################################################*/
.summaryboxeswrap {
	overflow: hidden;
	padding-bottom: 40px;
}

.summaryboxes-content {
	@include break-md(true) {
		margin-right: -40px;
		margin-left: -40px;
	}

	@include break-md {
		margin-top: -20px;
		margin-top: -20px;
	}
}

.summaryboxes {
	@include break-md(true) {
		display: table;
		border-collapse: separate;
		border-spacing: 40px 0;
		table-layout: fixed;
		width: 100%;
	}

  // only for variant 6 
  .sbw-v6 & {
    @include break-sm(true) {
      display: table;
      border-collapse: separate;
      border-spacing: 0;
      table-layout: fixed;
      width: 100%;
    }
  }
}

.smbxs-row {
	@include break-md(true) {
		display: table-row;
	}

	@include break-md {
		@include clearfix;
		display: block;
	}

  // only for variant 6 
  .sbw-v6 & {
    @include break-sm(true) {
      display: table-row;
    }
  }
}

.smbxs-box {
  background: #f1f1f1;
  font-size: 1.4rem;
  line-height: 1.5em;
  padding: 1em;

  @include break-md(true) {
    display: table-cell;
    width: 25%;
  }

  @include break-md {
    margin: 20px 2%;
    width: 46%;
    float: left;

    &:nth-child(2n+1) {
      clear: both;
    }
  }

  @include break-sm {
    margin: 20px 0;
    width: 100%;
    float: none;
  }


  // only for variant-6
  .sbw-v6 & {
    @include break-sm(true) {
      display: table-cell;
      float: none;
      margin: 0;
      width: 50%;
    }
  }

  &.is-color-danger,
  &.is-color-autopay,
  &.is-color-darkgray,
  &.is-color-warning,
  &.is-color-info {
    color: #fff;
  }

  &.is-color-lightgray {
    a {
      color: $brand-primary;
    }
  }

  .icon-exclamation {
    color: #fff;
    font-size: 1.6rem;
    position: relative;
    top: -2px;
    margin-right: 7px;
  }

  .icon-questionmark {
    font-size: 1rem;
    text-decoration: none;
  }

  /*a {
    color: #fff;
    text-decoration: underline;
  }*/

  p {
    font-size: 1em;
    line-height: 1.5em;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.smbxs-label-7 {
    font-size: 1.5em;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.smbxs-value-7 {
    font-size: 1.5em;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 34px;
    font-weight: 100;
    margin-bottom: 10px;
}

.smbxs-inner {
    padding: inherit
}

.smbxs-value {
  font-size: 1.5em;
	@include ellipsis;
}

.smbxs-label {
	@include ellipsis;
}

.smbxs-date {
	@include ellipsis;
}

.smbxs-list {
	list-style: none;
	padding-left: 0;
	padding: 10px 0;

	li {
		padding: 5px 0;
	}
}

.smbxs-link {
  color: #fff;
  text-decoration: underline;

  &:hover,
  &:active {
    color: #fff;
  }
}

.smbxs-paylink {
  font-size: 24px;
  color: $color-info;
  text-decoration: underline;
}

.smbxs-icon {
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }

  .is-color-white & {
    color: #000;

    &:hover,
	&:focus,
    &:active {
      color: #000;
    }
  }
}