/*######################################################
  badge
#######################################################*/
.badge {
	display: inline-block;
	font-size: 11px;
	line-height: 18px;
	min-height: 18px;
	min-width: 18px;
	padding: 0;
}