/*######################################################
	radio
#######################################################*/
.radio {
	font-size: 1.5em;
	line-height: 20px;

	label {
		padding-left: 32px;
		position: relative;

		~ label {
			margin-left: 20px;
		}

        .modal &{
            padding-top:1px;
            padding-bottom:1px;
        }
	}	

	input[type=radio] {
		@include extend-icomoon;
		margin-left: 0;
		position: absolute;
		left: 0;
		top: -4px;
		visibility: hidden;
		&:checked ~ .icon-radio:after {
			border: solid 1px #ffffff;
			position: absolute;
			left: 3px;
			top: 3px;
			border-radius: 50%;
			color: #201e1e;
			content: "";
			font-size: 22px;
			font-weight: bold;
			cursor: pointer;
			display: block;
			height: 18px;
			visibility: visible;
			width: 18px;
		}
	}

	&.is-radio-sm {
		font-size: 1.3em;
		label {
			font-weight: 500;
		}

		input[type=radio]:checked ~ .icon-radio:after {
			height: 14px;
			width: 14px;
		}
	}
}

.icon-radio {
	position: absolute;
	left: 0;
	top: 0;
	&:before {
		background-color: #ffffff;
		border: solid 1px rgba(32, 30, 30, 0.2);
		border-radius: 50%;
		color: #201e1e;
		content: "";
		font-size: 22px;
		font-weight: bold;
		cursor: pointer;
		display: block;
		height: 24px;
		visibility: visible;
		width: 24px;
	}

	.is-radio-sm & {
		top: 5px;

		&:before {
			height: 20px;
			width: 20px;
		}

        .modal &{
            top:0;
        }
	}
}

.radiolistgorup {
	border-top: 1px solid;
}

.radiolistitem {
	border-collapse: collapse;
	display: table;
	table-layout: fixed;
	width: 100%;
}

.radlsitm-col {
	border-bottom: 1px solid;
	padding: 10px 2px;
	display: table-cell;
	vertical-align: middle;

    .modal &{
        border:none;
        padding: 0px 2px;
    }
}

.radlsitm-button {
	text-align: right;
	width: 50px;
}