/*######################################################
	account profile page
#######################################################*/
.accountprofile-wrapper {
}

.accntprof-tab {
}

.accntprof-tab-content {
    padding: 30px 0;

    .form-wrapper {
        padding: 0;
    }

    .add-new-contact {
        margin-top: 10px;
        width: 50%;
        text-align: left;
    }
}

.accntprof-tab-inner {
    margin: auto;
    max-width: 840px;
}

.accntprof-contactdisplay {
    @include transition(max-height ease .2s);
   
    .is-editing & {
        max-height: 0;
    }
}

.accntprof-contactlist {
    .auth-para{
        margin-top:30px;
        font-size: 1.2em;
    }
}

.accntprof-contactitem {
    border-bottom: 1px solid;
    padding-bottom: 0px;
    padding-top: 0px;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
}

.ccntprof-contactrow {
    border-bottom: 1px solid;
    height: 120px;
    padding: 20px;
}

.accntprof-contactcol {

    p {
        line-height: 1em;
    }
}

.accntprof-contactbutton {
    @include break-sm(true) {
        padding-top: 20px;
    }
}

.accntprof-contactedit {
    @include transition(max-height ease .2s);
    max-height: 0;
    overflow: hidden;

    .is-editing & {
        max-height: 1000px;
    }
}

.form-wrapper {
    &.form-contactedit {
        padding: 27px 30px 25px 30px !important;

        .icon-trash {
            font-size: 13px;
        }

        .control-label {
            font-size: 1.3em;
            font-weight: 500;
        }

        .form-control-static {
            font-size: 1.4em;
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }
}

.accntprof-contactbutton {
    @include break-sm {
        margin-bottom: 10px;
    }
}

.saved-credit-cards, .saved-direct-debits {
    .divider {
        margin-top: 0;
    }

    .new-card-form, .new-direct-debit-form {
        margin-top: 3em;
        padding-right: 20px;
    }
}

.payment-new-card {
    .paymnt-btn-option {
        display: block;
    }

    // Hosted payment page
    .credit-card-form {
        height: 500px;
        border: 0;
        background-color: #fffaf4;
    }

    // .btn-cancel {
    //     padding-right: 0;
    // }
}

.saved-direct-debits{
    .btn-cancel{
        padding: 0;
        padding-right: 30px;
    }
}


