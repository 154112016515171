/*######################################################
  table
#######################################################*/
.table-wrapper {
    margin-bottom: 25px;

    @include break-md {
        margin-bottom: 95px;
    }

    table {
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-collapse: collapse;
        display: table;
        width: 100%;
    }

    th {
        border-top: none;
        border-right: 1px solid;
        font-size: 1.1em;
        line-height: normal;
        padding: 10px;
        text-transform: uppercase;

        &:last-child {
            border-right: none;
        }

        @include break-sm {
            display: none;
        }
    }

    tr {
        &.is-show-detail{

            @include break-sm{
                border-top:1px solid;
            }

            td{
                &:not(.td-tick) {

                    > span.tblwrap-td-title{
                        @include break-sm{
                            display: inline-block;
                        }
                    }

                    @include break-sm{
                        width: 85%;
                        float: right;
                        display: inline-block;
                        text-align: left;
                        height: auto;
                        padding: 10px 0 5px 0 ;
                        position: relative;
                    }
                }
                &:nth-child(2){
                    @include break-sm{
                        padding-top: 25px;
                    }
                }
                .td-status-content{
                    @include break-sm{
                        display: inline-block;
                    }
                }
                .dropdown-menu{
                    @include break-sm{
                        position: relative;
                        opacity: 1;
                        transform: scale(1, 1);
                        padding: 0;
                        border-color: transparent;
                        box-shadow: none;
                        top: auto !important;
                        right: auto;
                        left: -6px;
                        z-index: 11;
                        width: 100%;
                    }
                }
                .btn-detail{
                    @include break-sm {
                        position: relative;
                        display: none;
                    }
                }
                .btn-paynow{
                    @include break-sm {
                        position: relative;
                        top: auto;
                        right: auto;
                        display: inline-block;
                        margin: 0 0 10px -5px;
                        padding: 5px;
                        border: none;
                        font-size: 20px;
                    }
                }
                .td-status-content{
                    @include break-sm {
                        display: inline-block;
                    }
                }
                > span{
                    padding-top: 0;
                }

                @include break-sm {
                    position: absolute;
                    border-color: transparent;
                }
            }
        }

        &.is-row-danger {
            > td.td-balance > span.ng-binding {
                display: inline-block;
                padding: 1px 3px;
                color: #fff;
            } 
        }

        &.is-row-warning {
            > td.td-balance > span.ng-binding {
                display: inline-block;
                padding: 1px 3px;
                color: #fff;
            } 
        }


    }

    td {
        font-size: 1.5em;
        line-height: normal;
        padding: 20px 10px;

        > span {
            display: inline-block;

            &.tblwrap-td-title {
                display:none;
            }

            @include break-sm {

            }
        }

        &:not(.td-tick) {
            > span {
                @include break-sm {
                    padding-top: 6px;
                }
                @include break-xs {
                    padding-top: 0;
                }
            }
        }

        .btn-icon {
            font-size: 1.1em;
        }

        .icon-ellipses {
            font-size: 6px;
        }

        .btn-default {
            padding: 12px 10px;
            margin-top: -20px;
            position: absolute;
            top: 50%;

            @include break-sm{
                margin-top: -6px;
            }
        }

        .btn-icon {
            padding: 12px 5px;
        }

        .btn-notes {
            right: 252px;
        }

        .btn-roster {
            right: 220px;
        }

        .btn-attachment {
            right: 190px;
        }

        .btn-main {
            font-size: 1em;
            font-weight: 500;
            margin-left: 5px;
            padding: 8px 12px;
            right: 0;
            margin-right: 0;

            &.btn-paynow,
            &.btn-completed {
                right: 75px;
                margin-right: 15px;
            }
        }

        .btn-inline{
            position: relative;
            display: inline-block;
            margin-top: auto;
            margin-left: 10px;
        }

        .btn-detail {
            right: 20px;

            @include break-sm {
                border: none;
                right: 0px;
            }
        }

        @include break-sm {
            display: none;
            width: 27.6%;
            height: 65px;            
        }
    }

    .is-warning, .is-danger {
        position: relative;

        &:before {
            @include extend-icomoon;
            @extend .icon-exclamation;
            font-size: 18px;
            left: -26px;
            margin-top: -9px;
            position: absolute;
            top: 50%;

            @include break-sm{
                left: -20px;
            }
        }
    }

    .td-duedate,
    .td-docno,
    .td-tick,
    .td-action {
        @include break-sm {
            display: inline-block;
        }
    }
}

.table-pagination {
    padding: 30px 0 0 0;

    .icon-pagination {
        &-left {
            &:before {
            }
        }

        &-right {
        }
    }

    .pagination {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline;

            &.is-selected {
                a {
                    color: #ffffff;
                }
            }

            a {
                font-size: 1.6em;
                padding: 5px 10px;
                text-decoration: none;

                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                }

                @include break-sm {
                    padding: 5px 6px;
                }
            }
        }
    }

    .pageSizeSelect {
        width: 4.6em;
        padding: 5px;
    }

    .PageSizeSelectTitle {
        font-size: 1.5em;
        white-space: nowrap;
    }
}

.th-tick {
    width: 50px;
}

.th-sort {
    cursor: pointer;

    span {
        position: relative;
        padding-right: 14px;
        display: inherit;

        &:before {
            @include transition(transform ease .15s);
            @include extend-icomoon;
            @extend .icon-chevron-dropdown;
            font-size: 6px;
            margin-top: -4px;
            position: absolute;
            right: 0;
            top: 50%;
        }
    }

    &.th-sort-active-asc {
        span {
            &:before {
                @include transform(rotate(-180deg));
            }
        }
    }

    &.th-sort-active-desc {
        span {
            &:before {
                @include transform(rotate(0deg));
            }
        }
    }
}


.th-description {
    width: 180px;
}

.td-action {
    width: 300px;

    @include break-sm {
        width: auto;
    }

    > .td-action-itemwrap {
        position: relative;
    }

    button {
        @include break-sm {
            display: none;
        }
    }

    .btn-detail {
        display: block;
    }
}

.td-tick {
    @include break-sm {
        width: 15% !important;
    }

    span {
        @include border-radius(20%);
        border: 2px solid;
        cursor: pointer;
        display: block;
        height: 24px;
        position: relative;
        width: 24px;

        .is-selected & {
            &:before {
                @include extend-icomoon;
                @extend .icon-tick;
                color: #fff;
                font-size: 10px;
            }
        }
    }
}

.tblwrap-head {
    border-collapse: collapse;
    display: table;
    table-layout: fixed;
    width: 100%;

    @include break-md {
        display: block;
        position: relative;
    }

    .select-option { 
        float: left;

        select {
            border: none;
            min-width: 60px;
            padding-right: 30px;
        }
    }

    .dropdown-menu {
        left: 0;
        right: auto;
    }
}

.tblwrap-head-action,
.tblwrap-head-search {
    display: table-cell;
    padding: 30px 0;
    position: relative;
    vertical-align: middle;

    @include break-md {
        display: block;
        min-height: 106px;
    }
    @include break-sm{
        padding: 0px 10px 10px 10px;
        min-height: 45px;
    }
}

.tblwrap-head-search {
    width: 250px;
    
    @include break-lg{
        width: 370px;
    }
    @include break-md {
        min-height: inherit;
        padding-bottom: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    @include break-sm {
        width: 200px;
    }

    @include break-xs{
        width: 130px;
    }
}

.tblwrap-head-item {
    @include clearfix;
    float: left;
    padding-right: 10px;

    &:last-child {
        padding-right: 0;
    }

    .dropdown-toggle {
        background-color: transparent;
        border: none;
        font-size: 1.5em;
        font-weight: 500;
        line-height: 30px;
        padding-left:0;
    }

    .tblwrap-head-search &{
        float:right;
        position: relative;
    }

    @include break-sm{
        padding-right: 0;
    }
}

.tblwrap-control {
    float: left;
    max-width: 100px;
    min-width: 20px;
}

.tblwrap-label,
.tblwrap-label-ext {
    float: left;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 40px;
    margin: 0;
    padding: 0;
}

.tblwrap-label {
    padding-right: 5px;

    @include break-xs{
        width: 100%;
    }
}

.tblwrap-label-ext {
    float: none;
    font-size: 2em;
    font-weight: bold;
}

.tblwrap-select.bootstrap-select {
    > .dropdown-toggle {
        @include border-radius(0);
        border: none;
        padding: 5px 25px 5px 0;

        .caret {
            right: 0;
        }
    }

    .dropdown-menu > li > a {
        padding: 0 10px;
    }
}

.tblwrap-dropdown {
    .dropdown-toggle,
    .btn-default:active,
    .btn-default.active,
    &.open > .btn-default.dropdown-toggle {
        border: none;
    }

    .dropdown-menu > li > a {
        padding: 0 10px;
    }
}

.tblwrap-search {
    max-width: 250px;
    position: relative;

    .is-advancedsearch &{
        margin-right: 92px;
    }
}

.tblwrap-search-btn {
    border: none;
    background-color: transparent;
    font-size: 1.5em;
    height: 44px;
    margin-top: -22px;
    outline: none;
    position: absolute;
    right: 8px;
    top: 50%;
    width: 40px;
    z-index: 5;
}

.tblwrap-search-input {
    @include border-radius(3px);
    border: 1px solid;
    color: $gray;
    font-size: 1.4em;
    line-height: normal;
    padding: 14px 40px 14px 14px;
    position: relative;
    z-index: 1;
    max-width: 370px;
    width: 100%;
    .is-advancedsearch &{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 46px;
    }
    @include break-sm {
        width: 200px;
    }
    @include  break-xs{
        width: 100%;
    }
}

.tblwrap-overlay {
    @include box-shadow(0 0px 8px 2px rgba(0, 0, 0, 0.2));
    @include transition(bottom 0.25s ease);
    bottom: 0;
    color: #fff;
    left: 0;
    bottom: -140px;
    padding: 30px 0;
    position: fixed;
    width: 100%;
    z-index: 100;

    @include break-lg {
        bottom: -170px;
    }

    @include break-md {
        padding: 20px 0;
    }

    &.is-visible {
        bottom: 0 !important;
    }
}

.tblwrap-ovr-inner {
    border-collapse: collapse;
    display: table;
    table-layout: fixed;
    width: 100%;
}

.tblwrap-ovr-col {
    display: table-cell;
    vertical-align: middle;
}

.tblwrap-ovr-selectedcount {      
    font-size: 1.5em;
    font-weight: 400;
    line-height: normal;
    padding-left: 30px;
    width: 220px;

    .payment-history &{
        @include break-md{
            padding-left: 0;
        }
    }

    @include break-md {
        display: block; 
        font-size: 1.8em;
        padding-bottom: 20px;
        text-align: center;
        width: 100%;        
    }

}

.tblwrap-ovr-selectedtotal{
    display:block;
    margin-top:5px;
}

.tblwrap-ovr-links {    

    ul.dropdown-menu {
        list-style: none;
        margin: 0;

        li {
            display: inline-block;

            @include break-md {
                display: block;
            }
        }

        a {
            @include transition(color ease-in .2s);
            display: block;
            padding: 10px 16px;
            font-size: 1.6em;
            font-weight: 500;
            line-height: normal;

            @include break-md {
                padding: 10px;
                text-align: left;
            }

            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
                background-color: transparent;
            }
        }

        @include break-md(true) {
            @include transform(scale(1, 1));
            @include box-shadow (none);
            @include border-radius (0);
            border: none;
            background: none;
            opacity: 1;
            padding: 0;
            position: relative;
            float: none;
            text-align: center;
            width: 100%;
        }

        @include break-md {
            top: inherit;
            bottom: 100%;
            left: 0;
            right: inherit;
            padding: 10px 8px;
            @include transform-origin(bottom);
        }
    }    

    @include break-md {
        width: 80px;
        display:inline-block;
        float:left;
    }
}

.tblwrap-ovr-button {
    padding-right: 30px;
    width: 280px;

    .payment-history &{
        @include break-md{
            display: block;
            margin: 0 auto;
            float: none;
            text-align: center;
        }
    }

    @include break-lg {
        padding-right: 0;
        float: right;
    }

    @include break-sm {
        width: auto;
    }
}

.tblwrap-ovr-ellipses {
    border: none;
    display: none;
    padding: 24px 0;
    text-align: center;
    width: 70px;

    .icon-ellipses {
        font-size: 6px;
    }

    @include break-md {
        display: block;
    }
}

.tblwrap-ovr-payselected {
    font-weight: 600;
    padding: 12px 34px;

    .payment-history &{
        @include break-md{
            min-width: 220px;
        }
    }

    @include break-md {
        display: inline-block;
        font-size: 1.7em;
        padding: 19px 10px;
    }
}

.table-bills-payment {
    margin: 50px 0 0 0;
}

.table-grid {
    .nav-tabs {
        > li {
            &.active {
                a {
                    border-bottom: 5px solid !important;
                    color: #000000;
                }
            }

            > a {
                font-size: 1.8em;
                border: none !important;
                padding: 0 30px 30px 30px;
                margin: 0;
                border-radius: 0;

                &:hover {
                    background: none;
                }
            }
        }
    }

    .table-head-title{
        border-bottom: 1px solid;

        > span{
            font-size: 2em;
            padding: 0 0px 30px 0px;          
            color:#000;
            display:inline-block;
        }
    }
}
.tblwrap-advancedsearchbtn{
    border: 1px solid;
    font-size: 1.4em;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 11;
    display: inline-block;
    padding: 12px;
    padding-bottom: 13px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 46px;
}

.remove-item {
    a {
        font-size: 2em;
        margin-top: -13px;
        display: block;

        .returns & {
            font-size: 3em;
        }

        &:hover {
            text-decoration: none;
        }
    }
}
