/*######################################################
	email confirmation page
#######################################################*/
.emailconfirmation {
	.form-wrapper {
		padding-bottom: 0;
	}
}

.emlconf-title {
	h1 {
		font-size: 2em;
		font-weight: 600;
		margin-bottom: 13px;
	}

	h5 {
		font-size: 1.5em;
		line-height: normal;
		margin-top: 40px;
	}
}

.emlconf-content {
	background-color: #fff;
	margin: 50px auto 20px auto;
	max-width: 600px;
}

.emlconf-confirmation {
	color: #fff;
	font-size: 1.5em;
	line-height: normal;
	padding: 15px 20px 25px 20px;
	text-align: center;

	a {
		color: #fff;
	}
}


.emlconf-inner {
	padding: 40px;

    img{
        max-width: 340px;
    }
}

.emlconf-icocircle {
	@include border-radius(50%);
	background-color: #fff;
	border: 2px solid;
	display: inline-block;
	height: 26px;
	margin-right: 2px;
	padding-top: 2px;
	position: relative;
	text-align: center;
	top: 6px;
	width: 26px;

	.icon-tick {
		font-size: 10px;
		margin: auto;
	}
}

.emlconf-divider {
	border: none;
	margin-bottom: 25px;
	margin-top: 25px;
}

.emlconf-passlevel {
	display: inline-block;
	font-size: 1.4em;
	position: absolute;
	right: 0;

	em {
		font-style: normal;
	}
}