/*######################################################
	add service page
#######################################################*/
* {
    box-sizing: border-box;
}

.columns {
    float: left;
    width: 33.3%;
    padding: 8px;
}

.price {
    list-style-type: none;
    border: 1px solid #eee;
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.plan-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
}
.price:hover {
    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
}

.price .header {
    background-color: #436dda;
    color: white;
    font-size: 25px;
}

.price li {
    border-bottom: 1px solid #eee;
    padding: 20px;
    text-align: center;
    font-size:15px;
}

.price li a {
    color: #FFFFFF;
    text-decoration: none;
}

.price .grey {
    background-color: #eee;
    font-size: 20px;
}

.price .alter {
    background-color: #eee;
    font-size: 20px;
}

.checkbox.pull-right {
    margin: 0;
}

.pl-ziro {
    padding-left: 0px;
}

@media only screen and (max-width: 600px) {
    .columns {
        width: 100%;
    }
}


.custom-div {
    border: 1px solid red;
    padding: 10px 10px 40px 10px;
    position: relative;
    margin: 0 auto 0 auto;
    height: 100%;
}

.custom-div .signature {
    border: 1px solid orange;
    margin: 0 auto;
    cursor: pointer;
}

.custom-div .signature canvas {
    border: 1px solid #999;
    margin: 0 auto;
    cursor: pointer;
}

.custom-div .buttons {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.custom-div .sizes {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.container .sizes input {
    width: 4em;
    text-align: center;
}

.result {
    border: 1px solid blue;
    margin: 30px auto 0 auto;
    height: 220px;
    width: 568px;
}
