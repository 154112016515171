﻿/*######################################################
	report
#######################################################*/
.report{
	max-height: 0;
	overflow: hidden;
	@include transition(max-height linear .3s);

	.is-report &{
		max-height: 1024px;
	}
	> form{
		padding: 15px;
		border: 2px solid;
		margin-bottom: 30px;
	}
}

.report-item{
    border-bottom: 1px solid;
	padding: 0 15px;
    display:table;
    width:100%;
}

.report-label,
.report-group{
	display:table-cell;
    vertical-align:middle;
}

.report-label{
    text-align:right;
	padding-right: 15px;
	width: 135px;
}
.report-group{
    padding-top:15px;
    padding-bottom:15px;

}
.report-groupitem{
	border-bottom: 1px solid;
	margin: 0;
	padding: 0 15px;

	> div:first-child{
		padding-left: 0px;
	}
	> div:last-child{
		padding-right: 0px;
	}
}
.report-borderleft{
	border-right: 1px solid;
}
.report-select{
    position:relative;

    .checkbox {
        display: inline-block;
        vertical-align: bottom;
        margin-right: 15px;
    }

    .checkbox > label{
        font-weight: 400;
    }
    .dropdown-menu{
        opacity:1;
        transform: scale(1, 1);
    }
}

.report-formlabel{
	width: 40px;
	display: inline-block;
	position: absolute;
	font-size: 1.5rem;
	top: 10px;
	left: 0px;
	text-align: center;
	font-weight: 400;

	.report-groupleft &{
		left: -2px;
	}
}
.report-formitem{
	padding-left: 50px;
	position: relative;

	.report-groupright &{
		padding-left: 40px;
	}
}
.report-icon{
	position: absolute;
	font-size: 16px;
	top: 9px;
	margin-left: 10px;
	z-index: 11;

	.is-calendar &{
		top: 11px;
	}

}
.report-input{
	padding-top: 11px;
	padding-bottom: 10px;
	font-size: 1.3em;

	.is-currency &{
		padding-left: 25px;
	}
	.is-calendar &{
		padding-left: 30px;
	}

	&[type='date']{
		padding: 10px 0px 10px 30px;
		position: relative;

		.report-formitem &{
			line-height: 1.1;
		}

		&::-webkit-inner-spin-button {
			position: absolute;
			left: 0;
			opacity: 0;
			background-color: transparent;
			z-index: 12;
		}

		&::-webkit-calendar-picker-indicator {
			position: absolute;
			left: 0;
			opacity: 0;
			background-color: transparent;
			z-index: 12;
			width: 40px;
			height: 40px;
		}
	}

}
.report-action{
	padding-top: 35px;
	padding-bottom: 20px;

	.btn-borderless{
		margin-top: 15px;
	}
}
.report-groupleft{
	width: 52%;
	float: left;
}
.report-groupright{
	width: 48%;
	float: right;
}