/*######################################################
	payment page
#######################################################*/

.paymentwrappertab {
    border-collapse: collapse;
    display: table;
    table-layout: fixed;
    width: 100%;
    @include break-sm {
        display: block;
    }
}

.paymnt-left,
.paymnt-right {
    display: table-cell;
    vertical-align: top;
    @include break-sm {
        display: block;
    }
}

.paymnt-left {
    border-right: 2px solid;
    width: 240px;
    @include break-sm {
        border-bottom: 1px solid;
        border-right: none;
        width: 100%;
    }
}

.paymnt-right {
    padding-left: 60px;
    @include break-sm {
        padding-left: 0;
    }
}

.paymnt-navtabs {
    border: none;
    .open & {
        max-height: 500px;
    }
    @include break-sm {
        border-collapse: collapse;
        bottom: -2px; //display: table;
        position: relative;
        width: 100%;
    }
    >li {
        display: block;
        float: none;
        >a {
            display: block;
            font-size: 1.5em;
            padding: 28px 0 28px 70px;
            position: relative;
            outline: none;
            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
                border-color: transparent;
            }
        }
        &.active {
            a,
            a:hover,
            a:focus,
            a:active {
                @include border-radius(3px 0 0 3px);
                border-bottom: 1px solid;
                border-left: 1px solid;
                border-top: 1px solid;
                position: relative;
                outline: none;
                overflow: visible;
                &:before {
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-right: 6px solid;
                    content: "";
                    display: block;
                    height: 100%;
                    position: absolute;
                    right: -5px;
                    top: -1px;
                    width: 0;
                    box-sizing: initial;
                    -moz-box-sizing: initial;
                    -webkit-box-sizing: initial;
                }
            }
        }
        @include break-sm {
            display: table-cell;
            width: 100%;
            >a {
                padding: 20px 5px;
            }
            &.active>a,
            &.active>a:hover,
            &.active>a:focus,
            &.active>a:active {
                @include border-radius(3px 3px 0 0);
                border: none; //border-bottom: none;
                //border-right: 1px solid;
                //border-left: 1px solid;
                //border-top: 1px solid;
                &:before,
                &:hover:before,
                &:focus:before,
                &:active:before {
                    //border-bottom: 6px solid;
                    //border-left: 1px solid;
                    //border-right: 1px solid;
                    //border-top: none;
                    border: none;
                    bottom: -1px;
                    height: 0;
                    right: -1px;
                    top: inherit;
                    width: 100%;
                }
            }
        }
    }
    [class*="icon-"],
    [class^="icon-"] {
        font-size: 25px;
        position: absolute;
        right: 184px;
        top: 26px;
        @include break-sm {
            position: relative;
            right: inherit;
            top: 5px;
            margin-right: 4px;
            display: inline-block;
            width: 45px;
            font-size: 20px;
        }
        @include break-ss {
            margin-right: 0;
        }
    }
    .icon-creditcard {
        font-size: 20px;
        top: 28px;
        @include break-sm {
            top: 3px;
        }
    }
    .icon-bpay {
        font-size: 30px;
        top: 24px;
        @include break-sm {
            top: 6px;
        }
    }
}

.paymnt-tabcontent {}

.paymnt-masthead {
    border-collapse: collapse;
    display: table;
    table-layout: fixed;
    margin-bottom: 14px;
    width: 100%;
}

.paymnt-subhead {
    border-bottom: 2px solid;
    padding-bottom: 20px;
    margin-bottom: 40px;
    &-text {
        font-size: 1.5em;
    }
    @include break-sm {
        border-bottom-width: 1px;
    }
}

.paymnt-summary-intro {
    display: table-cell;
    padding: 22px 0 22px 0;
    vertical-align: middle;
    font-size: 1.5em;
    .paymnt-summary & {
        @include break-sm {
            display: block;
            text-align: left;
            font-size: 1.8em;
            padding: 12px 0 12px 0;
        }
    }
    >a {
        .paymnt-summary-lessshow & {
            @include break-sm {
                display: none;
            }
        }
        @include break-sm {
            display: block;
        }
    }
}

.paymnt-title {
    display: table-cell;
    padding: 22px 0 22px 0;
    vertical-align: middle;
    .paymnt-summary & {
        @include break-sm {
            display: block;
            padding: 12px 0 12px 0;
        }
    }
}

.paymnt-summary-wrapper {
    display: none;
    &.is-open {
        display: block;
    }
    .paymnt-summary-lessshow & {
        @include break-sm {
            display: block;
        }
    }
}

.paymnt-listgroup {
    font-weight: 600;
    @include break-sm {
        display: none;
    }
    .paymnt-summary-lessshow & {
        @include break-sm {
            display: block;
        }
    }
    .paymnt-summary-wrapper.is-open & {
        @include break-sm {
            display: block;
        }
    }
}

.paymnt-logoswrap {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    width: 140px;
    @include break-sm {
        width: 110px;
    }
}

[class*="paymnt-logo-"],
[class^="paymnt-logo-"] {
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    position: relative;
    margin-left: 8px;
    &:first-child {
        margin-left: 0;
    }
    .paymnt-option-logo & {
        @include border-radius(3px);
        @include transition(all ease-in .2s);
        border: 1px solid;
        background-position: center center;
        height: 50px;
        width: 80px;
        top: inherit;
    }
    .is-paymntbtn-selected & {
        background-color: #fff;
    }
}

.paymnt-logo-visa {
    @include img-retina("../images/logo-visa.svg", "../images/logo-visa.svg", 100%, auto);
    height: 11px;
    width: 33px;
    top: -2px;
    .paymnt-option-logo & {
        background-size: 41px auto;
    }
}

.paymnt-logo-mastercard {
    @include img-retina("../images/logo-mastercard.svg", "../images/logo-mastercard.svg", 100%, auto);
    height: 20px;
    width: 33px;
    top: 2px;
    .paymnt-option-logo & {
        background-size: 41px auto;
    }
}

.paymnt-logo-amex {
    @include img-retina("../images/logo-amex.svg", "../images/logo-amex.svg", 100%, auto);
    height: 15px;
    width: 40px;
    top: 1px;
    .paymnt-option-logo & {
        background-size: 41px auto;
    }
}

.paymnt-optionswrap {
    @include clearfix;
    margin: -10px -10px 30px -10px;
}

.paymnt-option {
    float: left;
    padding: 10px;
    width: 100%;
    /*@media only screen and (max-width: $screen-md) and (min-width: $screen-sm) {
        width: 100%;
    }

    @include break-ss {
        width: 100%;
    }*/
}

.paymnt-btn-option {
    @include transition(all ease-in .2s);
    @include border-radius(5px);
    background-color: #fff;
    border: 2px solid;
    border-collapse: collapse;
    display: table;
    overflow: hidden;
    outline: none;
    table-layout: fixed;
    width: 100%;
    min-height: 85px;
    cursor: pointer;
    &[disabled="disabled"] {
        cursor: default;
    }
    &:disabled {
        border-color: $background-color;
        background-color: $background-color;
    }
}

.paymnt-option-logo,
.paymnt-option-details,
.paymnt-option-action {
    display: table-cell; //height: 124px;
    padding: 20px 0;
    vertical-align: middle;
    text-align: left;
}

.paymnt-option-logo {
    text-align: center;
    width: 128px;
    i {
        font-size: 36px;
        font-weight: normal;
    }
    ~.paymnt-option-details {
        padding-left: 0px;
    }
    @include break-sm {
        width: 90px;
    }
}

.paymnt-option-details {
    padding-left: 40px;
    padding-right: 10px;
    width: 315px;
    span {
        display: block;
        font-size: 1.5em;
        font-weight: 600;
        line-height: normal;
        padding: 5px 0;
    }
}

.paymnt-option-action {
    .remove-item a {
        margin: 0;
        font-size: 3em;
        width: 20px;
    }
}

.new-card-form {
    margin-top: 3em;
}

.paymnt-btnwrap {
    padding: 40px 0;
    border-top: 1px solid;
    margin-top: 30px;
    @include break-sm {
        text-align: center;
    }
}

.paymnt-sectionwrap {
    overflow: hidden;
}

.paymnt-textonly {
    p {
        font-size: 1.5em;
    }
}

.paymnt-total {
    font-size: 1.5em;
    font-weight: 600;
    padding: 20px 0px 5px 0px;
    .paymnt-label {
        padding-top: 10px;
    }
}

.paymnt-note {
    em {
        padding-right: 2px;
    }
}

.paymnt-subtotal {
    @extend .paymnt-total;
    padding: 5px 0px 5px 0px;
}

.paymnt-section-inner {
    //border-bottom: 1px solid;
    margin-bottom: 20px;
}

.paymnt-form {
    padding: 0 0 10px 0;
    border-bottom: 1px solid;
    .control-label {
        font-size: 1.4em;
    }
}

.paymnt-value {
    padding-left: 10px;
    .list-group-item>& {
        float: right;
    }
    .paymnt-total>& {
        font-size: 1.6em;
        padding-left: 30px;
    }
    .paymnt-subtotal>& {
        font-size: 1.0em;
        font-weight: 500;
    }
}

.payment-note-label {
    margin-top: 15px;
}

.paymnt-radio {
    label {
        padding-bottom: 26px;
        line-height: 30px;
        * {
            display: inline-block;
        }
    }
    .form-control {
        position: relative;
        top: 4px;
        font-size: 1em;
        margin-left: 3px;
    }
    .icon-radio {
        top: 7px;
    }
}


/* spinner */

.paymnt-spinner {}

.paymnt-spinner-overlay {
    background: rgba(0, 0, 0, .4);
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 5555;
    @include break-sm {
        overflow-y: auto;
    }
}

.paymnt-spinner-content {
    background-color: #fff;
    margin: 100px auto 0 auto;
    max-width: 800px;
    padding: 60px;
    @include break-sm {
        margin-top: 70px;
        margin-bottom: 20px;
        padding: 30px;
        width: calc(100% - 30px);
    }
}

.paymnt-spinner-text {
    padding-top: 20px;
    .t-lg {
        margin-bottom: 20px;
        @include break-sm {
            font-size: 2em;
            font-weight: 600;
        }
    }
    >p {
        @extend .p-sm;
        line-height: 1.5em;
    }
}

.paymnt-date {
    margin-top: 20px;
    .form-wrapper {
        padding: 0;
    }
}

.autopayment {
    margin-top: 20px;
    padding-left: 35px;
    @include break-sm {
        padding-left: 20px;
    }
}

.autopaymnt-item {
    padding-right: 0;
    padding-left: 0;
}

.autopaymnt-day {
    padding: 0 5px;
    white-space: nowrap;
    .form-control {
        max-width: 60px;
        margin-right: 5px;
    }
}

.autopaymnt-notification {
    max-width: 218px;
    padding-left: 15px;
    @include break-md {
        padding-left: 5px;
    }
}

.paymnt-days-paymnent {
    padding-left: 5px;
}

.paymnt-tabsselected {
    display: none;
    &.active {
        display: inline-block;
    }
}

.paymnt-summary-details {
    @include break-sm {
        display: block;
        font-size: 16px;
    }
}

.paymnt-futuredate {
    margin-left: 35px;
}

.paymnt-summary {
    .checkbox.merchant-fee {
        font-weight: normal;
        font-size: 1.5rem;
        label:before {
            left: 30px;
        }
    }
}
.payment-schedule-actions.td-action-itemwrap {
    display: inline-block;
}
.payment-schedule-actions.td-action-itemwrap .btn-default.btn-detail {
    right: 0px;
    padding: 12px;
    margin: 0;
    position: relative;
    top: 0px;
}