/*######################################################
	buttons
#######################################################*/

.btn {
    @include transition(all ease-in .2s);
    position: relative;
    z-index: 5;
    &:active,
    &:focus,
    &.active {
        background-image: none !important;
        outline: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
}

.btn-lg {
    font-size: 2em;
    padding: 20px 50px;
    @include break-xs {
        display: block;
        padding-left: inherit;
        padding-right: inherit;
        width: 100%;
    }
}

.btn-xs {
    font-size: 1.4em;
    padding: 1px 10px;
}

.btn-sm {
    font-size: 1.4em;
    padding: 9px 18px;
}

.btn-md {
    font-size: 1.6em;
    padding: 12px 35px;
}

.btn-addnew {
    @include border-radius(50%);
    @include extend-icomoon;
    @extend .icon-addnew;
    @extend .btn;
    background-color: #fff;
    font-size: 60px;
    line-height: 60px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    &:hover,
    &:active,
    &.focus,
    &:focus,
    &.active {
        background-color: #fff;
    }
}

.btn-icon {
    background-color: transparent;
    border: none;
    &:hover,
    &:active,
    &.focus,
    &:focus,
    &.active {
        background-color: transparent !important;
        outline: none;
    }
}

.btn-add-task,
.btn-add-portfolio {
    i {
        margin-right: 8px;
        font-size: 0.8em;
    }
}

.btn-borderless {
    border: none;
    background: none;
    font-weight: 500;
    @include break-sm {
        padding-left: 0;
    }
}

.btn-wrapper {
    padding: 20px 0;
    @include break-sm {
        padding: 10px 0;
    }
}

.btn-default {
    &.inverse {
        background-color: transparent;
        border-color: transparent; // !important
        font-weight: 500;
        &:hover,
        &:focus,
        &:active {
            background-color: transparent !important;
        }
    }
}

.btn-primary {
    &.inverse {
        background-color: #fff;
    }
}

.btn-secondary {
    background-color: $gray-base;
}

.btn-white {
    background-color: #fff;
    color: $gray-base;
}

.btn-link {
    padding-left: 10px;
    padding-right: 10px;
}