/*######################################################
	filter search
#######################################################*/
.filtersearch {
	@include break-sm {
		.l-table,
		.l-tablecell {
			@include clearfix;
			display: block;
			position: relative;
		}
	}
}

.fltrsrch-body {
	display: table;
	table-layout: fixed;
	width: 100%;
}

.fltrsrch-content-column {
	display: table-cell;
}

.fltrsrch-row {

	.panel-row {
		table-layout: auto;
	}

	@include break-md {
		display: block;
		width: 100%;
	}
}

.fltrsrch-filter {
	@include break-lg {
		.fltrsrch-row,
		.fltrsrch-col {
			display: block;
			width: 100%;
		}

	}	

	@include break-sm {
		width: 100%;
	}
}

.fltrsrch-searchbox {
	width: 193px;

	@include break-lg {

		width: 40%;

		.fltrsrch-row,
		.fltrsrch-col {
			display: block;
			width: 100%;
		}

	}	

	@include break-sm {
		width: 100%;
	}
}


.fltrsrch-col-search {
	position: relative;
}

.fltrsrch-input-search {
	background-color: #f9f9f9;
	border: none;
	display: block;
	font-size: 2em;
	line-height: normal;
	overflow: hidden;
	padding: 14px 40px 14px 20px;
	right: 0;
	width: 100%;
}

.fltrsrch-btn-search {
	background-color: transparent;
	position: absolute;
	right: 10px;
	top: 50%;
	padding: 15px 10px;
	margin-top: -24px;
}

.fltrsrch-icon-search {
	font-size: 2.6rem !important;
}

.fltrsrch-col {
	padding-left: 3px;
	padding-right: 3px;

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}

	@include break-md {
		//float: left;
		padding-left: 0;
		padding-right: 0;
		min-height: 52px;
		width: 50%;

		.select-option select {
			padding-left: 0;
		}

		&:nth-child(2n+1) {
			clear: both;
		}
	}

	@include break-sm {
		width: 100%;
	}

	.btn-group.dropdown {
		width: 100%;

		.dropdown-toggle {
			@include clearfix;
			text-align: left;
			width: 100%;

			.caret {
				float: right;
			}
		}

		.dropdown-menu {
			width: 100%;
		}
	}
}

.fltrsrch-col-label {
	color: $gray-dark;
	font-weight: bold;
}

.fltrsrch-label {
	font-weight: normal;
}

.fltrsrch-select {
	float: left;
	width: 130px;
}
