﻿/*######################################################
    claim page
#######################################################*/
#modal-claim-detail {
    .modal-header {
        button.btn-modal-edit {
            margin-right: 15px;
        }
    }
}
.claim-details {
    margin-top:15px;
}
.claim-complete-button-top {
    top: -15px;
}
.claim-button-position{
    position:unset !important;
}
.dispute-manage-assignee-space
{
    padding-left:15px;
}
.dispute-add-button-top{
    margin-top:25px;
}
