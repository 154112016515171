.document-list{
    .btn-main{
        right: 60px !important; 
    }
    
    .btn-detail{
        right: 0 !important;
    }
}

.purchase-order-list{
    .btn-main{
        right: 60px !important; 
    }
    
    .btn-detail{
        right: 0 !important;
    }
}