/*######################################################
	layout
#######################################################*/
.l-block {
	display: block;
}

.l-table {
	border-collapse: collapse;
	display: table;
	margin: -1px;
	table-layout: fixed;
	width: 100%;

	&.is-table-break-md {
		@include break-md {
			display: block;
			margin: 0;
		}
	}

	&.is-table-break-sm {
		@include break-sm {
			display: block;
			margin: 0;
		}
	}

}

.l-tablecell {
	display: table-cell;
	vertical-align: top;

	&.has-rightborder-divider {
		border-right: 2px solid $border-color;
	}

	.is-table-break-md & {
		@include break-md {
			display: block;

			&.has-rightborder-divider {
				border-right: none;
			}
		}
	}

	.is-table-break-sm & {
		@include break-sm {
			display: block;

			&.has-rightborder-divider {
				border-right: none;
			}
		}
	}

}

.l-inline {
	display: inline-block;
}