.banner{

    img{
        width: 100%;
        margin-bottom: 40px;

        @include break-xs {
            margin-bottom: 20px;
		}
    }

}