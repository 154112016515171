/*######################################################
  list group
#######################################################*/
.list-group {
	border-top: 1px solid;
}

.list-group-item {
	@include clearfix;
	border-bottom: 1px solid;
	display: block;
	font-size: 1.5em;
	padding: 15px 0;
}