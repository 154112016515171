/*######################################################
	summary charts
#######################################################*/
.summarycharts {	
	border-collapse: collapse;
	display: table;
	table-layout: fixed;
	min-width: 100%;
    margin-bottom: 30px;

	@include break-md {
		@include clearfix;
		display: block;
	}

	&.is-opr {
		margin: -30px -40px;

		@include break-lg {
			@include clearfix;
			display: block;
		}

		@include break-md {
			margin: 0;
		}
	}

}

.smchrt-graph,
.smchrt-balance {
	display: table-cell;
	vertical-align: top;

	@include break-md {
		display: block;
		width: 100%;
	}

	@include break-sm {
		padding: 0;
	}
}

.smchrt-graph {
	border-right: 1px solid;
	//padding: 20px 10px 20px 0;
    padding-top: 15px;
	width: 382px;

	.is-opr & {
		border-right: none;
		padding: 20px 10px 20px 20px;
		width: 402px;

		@include break-md {
			padding: 15px;
		}
	}

	img {
		@extend .img-responsive;
	}

	@include break-lg {
		display: block;
	}

	@include break-md {
		border: none;
		margin: auto;
		max-width: 360px;
		padding: 15px;
		width: 100%;
	}

    .footer {
        font-size: 1.3em;
        margin-top: 10px;
    }
}

.smchrt-balance {
	padding: 0 0 20px 20px;

	@include break-md {
		padding: 10px 0;
	}

	.is-opr & {
		padding: 0 0 0 20px;
		position: relative;

		&:before {
			content: "";
			border-left: 1px solid;
			display: block;
			height: 220px;
			left: 10px;
			position: absolute;
			top: 50%;
			margin-top: -110px;
			width: 0;
		}

		@include break-lg {
			display: block;
		}

		@include break-md {
			padding: 0;
			&:before {
				display: none;
			}
		}
	}
}

.smchrt-row  {	
	border-collapse: collapse;
	display: table;
	table-layout: fixed;
	width: 100%;

	~ .smchrt-row {
		margin-top: 20px;
	}
	.is-opr & {
		@include break-md {
			@include clearfix;
			display: block;
		}
	}

	@include break-sm {
		border: none !important;
		display: block;

		~ .smchrt-row {
			margin-top: 0;
		}
	}

	.t-md {
		line-height: 50px;
	}
}

.smchrt-col {
	display: table-cell;
	padding: 10px 0px 10px 30px;
	vertical-align: top;
	width: calc(100% / 3);

	&:first-child {
		padding: 10px 10px;
	}
	
	.is-opr & {
		@include break-md {
			display: block;
			float: left;
			width: 50%;
		}

		@include break-sm {
			width: 100%;
		}
	}

	@include break-sm {
		@include clearfix;
		border: none !important;
		border-bottom: 1px solid !important;
		display: block;		
		padding: 10px;
		//text-align: center;
		width: 100%;

		&:first-child {
			padding: 10px;
		}
	}
}


.smchrt-label {
	display: block;
	font-size: 1.5em;
	font-weight: 700;
	line-height: 1.6em;

	@include break-sm {
		font-size: 2em;
		font-weight: 500;
		line-height: normal;
	}
	.is-opr-dashboard & {
		font-size: 2em;
		font-weight: 500;
	}
}

.smchrt-value {
	display: block;
	font-weight: 500;
	padding: 10px 0;

	@include break-sm {
		float: left;
		padding-right: 10px;
		width: 60%;
	}
	.is-opr-dashboard & {
		font-size: 2.4em;
		font-weight: 100;
	}
}

.smchrt-valuepercentage {
	display: block;
    font-size: 1.4em;
    line-height: normal;
    padding-bottom: 10px;

    &:before {
    	font-size: 1em;
    	margin-right: 5px;
    }

    &.is-value-up {
    	&:before {
    		@include extend-icomoon;
    		@extend .icon-arrow-up-right;
    	}
    }

    &.is-value-down {
    	&:before {
    		@include extend-icomoon;
    		@extend .icon-arrow-down-left;
    	}
    }
}

.smchrt-list {
	@extend .list-unstyled;
	font-size: 1.5em;
	font-weight: 500;
	@include break-sm {
		float: right;
		width: 40%;
	}
}

.smchrt-border-bottom {
	border-bottom: 1px solid;
	padding-bottom: 10px;
}

.smchrt-border-right {
	border-right: 1px solid;
}

.smchrt-btnpaynow {
	margin: auto;
	max-width: 320px;
	width: 100%;
	@include break-sm {
		width: auto;
	}
}

.smchrt-sm-clear {
	@include break-sm {
		@include clearfix;
		border-bottom: 1px solid !important;
	}
}

.smchrt-total {
	@include break-sm {
		border: none !important;
		float: left;
		width: 60%;

		~ .smchrt-total {
			padding-left: 0;
			width: 40%;

			.smchrt-list {
				float: none;
				width: 100%;
			}
		}
	}
}

.smchrt-paynow-desktop {
	@include break-sm {
		display: none;
	}
}

.smchrt-paynow-mobile {
	margin: inherit;
	
	@include break-sm(true) {
		display: none;
	}
}

.smchrt-exclamation {
	position: relative;
	top: 3px;
	left: 0;
	margin-right: 3px;
}

.smchrt-quickact {
	padding: 30px;
	> ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	.is-opr & {
		@include break-md {
			width: 100%;
		}
	}
}

.smchrt-quickactlink {
	@include transition(color ease-in .2s);
	color: #fff;
	display: inline-block;
	font-size: 1.5em;
	font-weight: 400;
	padding: 5px 0;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
}

.smchrt-quickacttitle {
	display: block;
	font-size: 1.1em;
	font-weight: 600;
	padding-bottom: 10px;
	text-transform: uppercase;

	&:before {
		@include extend-icomoon;
		@extend .icon-quick-actions;
		color: #fff;
		margin-right: 10px;
	}
}

.smchrt-balance-list {
	padding: 20px 30px 20px 0;

	@include break-md {
		padding: 20px 10px;
	}
}

.smchrt-row-inner {	
	border-bottom: 1px solid;
	padding: 20px 0;

	&:last-child {
		border: none;
	}
}

.smchrt-balist-item {
	@include clearfix;
	font-size: 1.5em;
	line-height: normal;

	span {
		float: right;
	}
}