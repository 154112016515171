/*######################################################
	modal
#######################################################*/
.modal-dialog {
    margin: 120px auto;

    &.headerless {
        padding: 60px 30px 0 30px;

        @include break-sm {
            padding: 60px 0 0 0;
        }
    }

    @include break-sm(true) {
        max-width: 850px;
        width: 100%;
    }

    @include break-sm {
        width: 90%;
    }
}

.modal-content {
    @include box-shadow(0 4px 10px 2px rgba(0, 0, 0, 0.12));
    padding: 10px 30px;
    border-radius: 0;
    border: none;

    .close {
        font-size: 40px;
        font-weight: normal;
        text-shadow: inherit;
        opacity: 1;
        height: 50px;
        line-height: 50px;
        outline: none;
        width: 50px;
        position: absolute;
        top: -60px;
        right: -15px;
        color: #ffffff;

        .headerless & {
            position: absolute;
            top: -50px;
            right: -34px;
            z-index: 222;

            @include break-sm {
                right: -8px;
            }
        }

        @include break-sm {
            width: 30px;
            right: -9px;
        }
    }
}

.modal-body .table-wrapper {
    overflow-x: scroll;
}

.modal-body {
    padding: 30px 0 20px 0;
}

.modal-footer {
    padding: 30px 0 20px 0;
}

.modal-title {
    font-size: 2em;
    margin: 6px 0 10px 0;

    .is-has-button & {
        padding-right: 100px;
    }
}

.modal-header {
    padding: 20px 0 15px 0;
}

.modal-backdrop {
    background: #000000;

    &.in {
        opacity: 0.6;
    }
}

.btn-modal-edit {
    position: absolute;
    right: 0;
    top: 15px;
}

.modal-arrow {
    > a {
        position: absolute;
        top: 50%;
        color: #fff;
        font-size: 30px;

        &:hover, &:active, &:focus {
            text-decoration: none;
        }
    }

    .modal-arrow-prev {
        left: - 50px;
    }

    .modal-arrow-next {
        right: -50px;
    }
}

.modal-image {
    max-width: 100%;
    margin: 0 auto;
    display: block;
}

.claim-detail-modal-window .modal-dialog {
    width: 75%;
    max-width: 75%;
}
