/*######################################################
	select
#######################################################*/
select {
    @include border-radius(4px);    
    background-color: transparent;
    border: 1px solid;
    font-size: 1.5em;
    line-height: 1.4em;
    outline: none !important;    
    padding: 14px 40px 14px 15px;
    position: relative;
    width: 100%;
    z-index: 2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    .table-wrapper & {
        padding: 10px 20px 14px 10px;
    }

    &:hover {
        cursor: pointer;
    }

    &:disabled,
    &[disabled="disabled"] {
        background-color: #eee;
        cursor: default;
    } 

    td &,
    th & {
        font-size: 14px;
    }   
}

.select-option { 
    position: relative;

    &:before {
        @include extend-icomoon;
        @extend .icon-chevron-dropdown;

        margin-top: -5px;
        position: absolute;
        right: 15px;
        font-size: 0.65em;
        top: 50%;
        z-index: 10;

        td &,
        th & {
            font-size: 6.5px;
            margin-top: -3px;
        }   
    }

    .no-border {
        border: none;
    }
    

    @media screen\0 {
        /* To target IE */        
        select::-ms-expand {
            display: none;
        }
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
       /* IE10+ specific styles */  
       select::-ms-expand {
            display: none;
        }
    }
}