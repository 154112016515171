/*######################################################
  dropdown
#######################################################*/
.open > .dropdown-menu {
    transform: scale(1, 1);
    opacity: 1;
}

.dropdown-menu {
    display: block;
    opacity: 0;
    transform-origin: top;
    animation-fill-mode: forwards;
    transform: scale(1, 0);
    transition: all 0.2s linear;
    padding: 8px 12px;
    right: 0;
    left: auto;
    li {
        a {
            font-weight: 500;
            padding: 5px 6px;
            font-size: 1.6em;
        }
    }
}

.dropdown {
    &-menu {
        li {
            a {
                font-size: 1.6em;
            }
        }
    }
}

.dropdown-toggle {
    &:hover,
    &:focus
    &.focus,
    &:active,
    &.active,
    .btn-group.open > &,
    .open > & {        
        @include box-shadow(none);
        background-color: transparent !important;
    }

    > .caret {        
        @include extend-icomoon;
        @extend .icon-chevron-dropdown;
        border: none;
        font-size: 7px;
        height: auto;    
        margin-left: 10px;
        width: auto;
    }
}

.dropdown-default {
    .dropdown-toggle,
    .btn-default:active,
    .btn-default.active,
    &.open > .btn-default.dropdown-toggle {
        @include box-shadow(none);
        background-color: transparent;
        font-size: 1.5em;
        font-weight: 500;
        padding: 5px 20px 5px 0;

        &:hover,
        &:focus,
        &.focus {
            @include box-shadow(none);
            background-color: transparent;
        }

        .caret {
            @include extend-icomoon;
            @extend .icon-chevron-dropdown;
            border: none;
            font-size: 8px;
            height: inherit;
            margin-left: 8px;
            width: auto;
        }
    }

}
