/*######################################################
	returns page
#######################################################*/
.returns {	
	padding: 20px 0 10px 0;
}
.return-list .btn-attachment{
    margin-right:40px;
}
.rtrns-group {
	border-collapse: collapse;
	border-top: 1px solid;
	display: table;
	table-layout: fixed;
	width: 100%;

	@include break-xs {
		display: block;
	}

}

.rtrns-num,
.rtrns-form {
	display: table-cell;
	vertical-align: top;

	@include break-xs {
		display: block;
		width: 100% !important;
	}
}

.rtrns-num {
	font-size: 1.5em;
	font-weight: 500;
	line-height: normal;
	padding: 40px 10px 10px 10px;
	width: 48px;

	@include break-xs {
		padding: 20px 10px 5px 10px;
	}
}

.rtrns-form {
	@include clearfix;
	padding: 36px 0 10px 0;
	width: 100%;

	@include break-xs {
		padding: 5px 10px 20px 10px;
	}
}
.rtrns-colwrap {
	@include make-row;
}
.rtrns-column {
	float: left;
	
	@include break-md(true) {
		width: 50%;

		~ .rtrns-column {
			padding-left: 20px;
		}
	}

	@include break-md {
		width: 100%;
	}
}

.rtrns-itemwrap {
	padding: 0 10px;	
	float: left;
	@include break-xs(true) {
		width: 50%;
	}

	@include break-xs {
		width: 100%;
	}
}

.rtrns-btnwrap {
	text-align: right;
	position: relative;

	@include break-sm {
		text-align: center;
		padding-top: 50px;
	}
}

.rtrns-addnew {
	position: absolute;
	left: 50%;
	margin-left: -30px !important;
	top: -30px;
}

.rtrn-isbn{
    .open > .dropdown-menu{
        min-width:215px;
    }
}

.rtrn-ordernumber{
    .open > .dropdown-menu{
        min-width:215px;
    }
}

.field-wrap{
    .checkbox{
        display:inline-block;
        vertical-align:bottom;
        margin-right:8px;

        > input:checked ~ label:before{        
            color:#fff;       
        }

        > label{            
            line-height: 22px;
            font-weight: 400;

            @include break-sm{
                padding-left: 28px;
            }
        }

        @include break-sm{
            font-size: 13px;
        }

        @include break-xs {
            margin-bottom:7px;
        }
    }
}

.invoicestabwrap {

    .nav-tabs{
        @include break-sm{
            display: none;
        }
    }

    .tabdrop-activelabel{
        font-size: 20px;
    }
}

.returnstabwrap-navtabs{
    list-style: none;
    margin: 20px 0 0;
    padding: 0;
    display: none;

    @include break-sm{
        display: block;
        @include transition(max-height linear .3s);
        max-height: 0;
        overflow: hidden;
    }

    .returnstabwrap-tabsmobile.open &{
        @include break-sm{
            max-height: 300px;
        }
    }
}

.returnstabwrap-navitemtab{
    display: block;
}

.returnstabwrap-navlinktab{
    font-size: 2em;
    padding: 0 30px 20px 10px;
    display: block;

    &:hover, &:focus, &:active{
        text-decoration: none;
    }
}

.form-wrapper {
	&.form-return-upload {	
		.form-control-static {
			font-size: 1.4em;
	        padding-top:3px;
	        padding-bottom:3px;
            padding-left: 15px;
		}
        .textarea-error-messages{
            height: 250px;
        }
	}
}

