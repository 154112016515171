@import "skins/sp/variables"; 
/* order is important */
@import "helpers/mixins";
@import "frameworks/customBootstrap";

@import "vendor/icomoon";
@import "vendor/customfont";

@import "base/reset";
@import "base/typography";

@import "components/badge";
@import "components/buttons";
@import "components/checkbox";
@import "components/dropdown";
@import "components/file-upload";
@import "components/form";
@import "components/list-group";
@import "components/loading";
@import "components/modal";
@import "components/navigation";
@import "components/panel";
@import "components/radio";
@import "components/select";
@import "components/tab";
@import "components/table";
@import "components/date-picker";

@import "layout/account-message";
@import "layout/contactdetails";
@import "layout/content";
@import "layout/filter-search";
@import "layout/footer";
@import "layout/grid";
@import "layout/header";
@import "layout/masthead";
@import "layout/quickaction";
@import "layout/summaryboxes";
@import "layout/summarycharts";
@import "layout/summaryflat";
@import "layout/validation";
@import "layout/contact-me-to-discuss";
@import "layout/tab-dropdown";
@import "layout/advanced-search";
@import "layout/report";
@import "layout/_package-summary";

@import "pages/account";
@import "pages/account-profile";
@import "pages/confirmation";
@import "pages/contact";
@import "pages/email-confirmation";
@import "pages/forgot-password";
@import "pages/invoices-and-payments";
@import "pages/login";
@import "pages/operator-dashboard";
@import "pages/operator-tasks";
@import "pages/part-pay-invoices";
@import "pages/payment";
@import "pages/reminder-strategy-template";
@import "pages/template";
@import "pages/returns";
@import "pages/services";
@import "pages/payment-history";
@import "pages/tasks";
@import "pages/user-profile";
@import "pages/ad-hoc-payment";
@import "pages/autopay.scss";
@import "pages/setup-complete.scss";
@import "pages/welcome-banner.scss";
@import "pages/banner.scss";
@import "pages/business-details.scss";
@import "pages/load-summary.scss";
@import "pages/documents.scss";
@import "pages/claim.scss";
@import "pages/add-service.scss";

//@import "skins/admin";



/* order is important */

@media print{
    .loading-spinner{
        display:none;
    }
}
.notification-box-wrapper {
    overflow:hidden;
    margin-bottom: 30px;
}
.notification-box-content {
    border-bottom: solid 1px #d0d0d0;
    margin: 5px 0px;
    overflow:hidden;
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #afafaf;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}
