/*######################################################
	panel
#######################################################*/
.panel-body {
	padding: 30px 40px;
}

.panel-row {
	border-collapse: collapse;
	display: table;
	table-layout: fixed;
	width: 100%;
}

.panel-column {
	display: table-cell;
}

