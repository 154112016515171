/*######################################################
	load summary widget
#######################################################*/
.load-summary {
    .flex-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .item-wrapper {
        height: 200px;
        background-color: white;
        width: 32%;
    }

    .title {
        padding: 10px;
        font-weight: normal;
    }

    .statistic {
        text-align: center;
        font-size: 7em;
    }
}

