/*######################################################
  part pay invoices page
#######################################################*/
.partpayinvoiceswrap {

}

.papainvcs-table {
	border-bottom: 1px solid;
	border-top: 1px solid;
	border-collapse: collapse;
	display: table;
	width: 100%;

	th,
	td {
		font-size: 1.5em;
		padding: 10px;
		//min-width: 116px;
		.form-control {
			font-size: 1em;
		}

		.bootstrap-select > .dropdown-toggle {
			font-size: 1em;
		}

		@include break-md{
			font-size: 1.4rem;
		}
	}
	th {
		border-right: 1px solid;

		&:last-child {
			border-right: none;
		}
		&:nth-last-child(2){
			border-right: none;
		}

		> span{
			@include break-md{
				display: block;
			}
		}

		@include break-sm{
			display: none;
		}
	}

	td {

		> span {
			display: inline-block;
			padding-top: 14px;

			@include break-sm{
				padding: 0;
			}
		}

		&.td-payamount,
		&.td-varreason{
			@include break-sm {
				width: 50%;
				float: left;
			}
		}
		&.td-payamount{
			@include break-sm {
				padding: 10px 10px 20px 0;
			}
		}
		&.td-varreason{
			@include break-sm {
				padding: 10px 0px 20px 10px;
			}
		}
		&.td-descript,
		&.td-docamount{
			@include break-sm{
				display: none;
			}
		}

		.form-field-sm{
			@include break-sm{
				max-width: 100%;
				min-height: 49px;
			}
		}

		&.remove-item{
			text-align: center;

			> a{
				font-size: 16px;
				font-weight: normal;
				margin-top: 0;

				&.papainvcs-viewdetail{
					font-size: 14px;
					display: none;

					@include break-sm{
						display: inline-block;
					}
				}

				@include break-sm{
					margin-bottom: 20px;
				}
			}

			@include break-sm{
				position: absolute;
				top: 20px;
				right: 0;
				width: 120px;
				text-align: right;
			}
		}

		.td-label{
			display: none;

			@include break-sm{
				display: inline-block;
			}
		}

		@include break-sm{
			display: inline-block;
			width: 80%;
			border-color: transparent;
			text-align: left;
		}

	}

	> thead > tr > th {
		border-bottom: none;
	}
	> tbody > tr{
		> td{
			@include break-sm{
				border: none;
				padding: 2px 0;
			}
		}

		@include break-sm{
			padding-top: 20px;
			display: inline-block;
			position: relative;
		}
	}
	> tfoot > tr > td{

		&:last-child{
			@include break-sm{
				border: none;
				display: none;
			}
		}
		&:first-child{
			padding-top: 20px;
		}

		&.td-varreason{
			@include break-sm {
				width: 100%;
				border-top:none;
				padding-left: 0;
			}
		}

		@include break-sm{
			width: 100%;
			display: inline-block;
			border-bottom: none;
			padding-left: 0;
			padding-right: 0;
		}
	}
	.th-payamount {
		min-width: 184px;

		@include break-lg{
			min-width: 100px;
		}
	}

	.th-descript {
		min-width: 184px;

		@include break-lg{
			min-width: 100px;
		}
	}

	.th-varreason {
		min-width: 224px;

		@include break-lg{
			min-width: 155px;
		}
	}
	.tr-textbox{
		@include break-sm{
			width: 100%;
			padding-top: 0;
			padding-bottom: 15px;
		}
	}
    .tr-textbox > td{
        border-top:0px;

	    @include break-sm{
		    width: 100%;
		    display: none;
	    }
	    &.papainvcs-txttellmore{
		    @include break-sm{
			    display: inline-block;
		    }
	    }
    }

    .tr-textbox > td > textarea{
        resize:none;
    }

	.show-detail{
		td{
			&.td-descript, &.td-docamount{
				@include break-sm{
					display: inline-block;
				}
			}
		}
	}

	> tbody + tbody{
		@include break-sm{
			border-top-width:1px;
		}
	}
}

.papainvcs-sectionwrap {
	padding: 20px 0;

	~ .papainvcs-sectionwrap {
		padding-top: 0;
	}

	@include break-sm{
		padding-top: 0;
	}
}

.papainvcs-total {
	span {
		display: block;
		font-size: 1.5em;
		font-weight: 600;
		line-height: 30px;
	}

	.papainvcs-paytot {
		font-size: 2em;
	}
}

.papainvcs-tablewrap {
	@include break-sm {
		border: none;
	}
}
