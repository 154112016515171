﻿
.loading-spinner-icon{
    display: block;
    font-size: 24px;
    margin-bottom: 30px;

    &.icon-exclamation{
        font-size: 26px;
    }
}
.loading-spinner-btn{
    font-size: 14px;
    padding: 5px 10px;
    display:block;
    margin: 30px auto;

    @include break-sm{
        margin: 20px auto 0;
    }
}
.loading-spinner-overlay {
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 5555;

    @include break-sm{
        overflow-y: auto;
    }
}
.loading-spinner-content {
    background-color: #fff;
    margin: 100px auto 0 auto;
    max-width: 800px;
    padding: 60px;

    @include break-sm{
        margin-top: 70px;
        margin-bottom: 20px;
        padding: 30px;
        width: calc(100% - 30px);

    }
}
.loading-spinner-text {
    padding-top: 20px;
    .t-lg {
        margin-bottom: 20px;

        @include break-sm{
            font-size: 2em;
            font-weight: 600;
        }
    }
    > p{
        @extend .p-sm;
        line-height: 1.5em;
    }
}
.loading-spinner-title{
    .is-error{
        font-size:2em;
    }
}