/*######################################################
    validation
#######################################################*/
.field-error {
    color: $color-danger;
    font-size: 1.2em;
    margin-top: 5px;
}

.ng-invalid{
    &.ng-touched, .ng-submitted &:not(ng-form){
        border: solid $color-danger 2px;
    }
}

.ng-invalid.ng-touched~.input-group-addon{
    border-top: solid $color-danger 2px;
    border-left: solid $color-danger 2px;
    border-bottom: solid $color-danger 2px; 
}

.validation-summary {
    margin-top: 1em;
}

.summary{
    margin-top: 1em;
    margin-bottom: 1em;
}

.error-text{
    color: $color-danger;
    font-size: 1.2em;

    &.error-md{
        font-size: 1.4em;
    }

    &.error-lg{
        font-size: 1.6em;
    }
}

.success-text{
    color: $color-success;

    &.success-md{
        font-size: 1.4em;
    }

    &.success-lg{
        font-size: 1.6em;
    }
}

.info-text{
    color: $color-info;

    &.info-md{
        font-size: 1.4em;
    }

    &.info-lg{
        font-size: 1.6em;
    }
}
