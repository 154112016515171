/*------------------------------------------
	font-face levo custom made
------------------------------------------*/
@font-face {
    font-family: 'discfont';
    src: url('../fonts/customdiscfont/customdiscfont.eot');
    src: url('../fonts/customdiscfont/customdiscfont.eot') format('embedded-opentype'),
         url('../fonts/customdiscfont/customdiscfont.woff2') format('woff2'),
         url('../fonts/customdiscfont/customdiscfont.woff') format('woff'),
         url('../fonts/customdiscfont/customdiscfont.ttf') format('truetype'),
         url('../fonts/customdiscfont/customdiscfont.svg#customdiscfont') format('svg');
}
