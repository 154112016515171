/*######################################################
	autopay page
#######################################################*/
.autopay {
    h3 {
        margin-bottom: 1em;
    }

    .inner-text {
        margin-bottom: 2em;
    }

    .checkbox-row {
        margin-top: 2em;
    }
}
